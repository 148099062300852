<script>
  import { conf, permissions, modal, user, lang, token, route  } from './stores.js'
  import { api, cdn, translate, i18n, setRoute, matchRoute, sleep, inArray, asArray, isSame, getById, genCode, anchor, uuid, dateTimeFormat, clipBoard } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Inputs from './G_Inputs.svelte'
  import InputsGroup from './G_InputsGroup.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import Img from './G_Img.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import LineMenu from './G_LineMenu.svelte'
  import Spinner from './G_Spinner.svelte'
  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], objsA=[], obj={}, objA={}, keyword="", sortBy={startDate:1}, filter={valid:true}, tagsSelected=[], subevents=[];

  let listsReload=0

  $: list(keyword, filter, tagsSelected, sortBy)

  $: if(matchRoute($route,'/events/manage/:id/participants')) listParticipants(listParticipantsKeyword, listParticipantsSortBy, listParticipantsFilter, subevents, listsReload)
  $: if(matchRoute($route,'/events/manage/:id/exhibitors')) listExhibitors(listExhibitorsKeyword, listExhibitorsSortBy, listExhibitorsFilter, subevents, listsReload)

  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.events) $modal={
      cancel:true,
      title:i18n("licence--No more events"), 
      msg:i18n("licence--No more events msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else{
      await get()
      setRoute("/events/manage//design")
    }
  }

  async function edit(id){
    await get(id)
    setRoute(`/events/manage/${id}/dashboard`)
  }

  async function update(){
    obj = await api(`/app/customers/events/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    //edit(obj.id)
    //setRoute("/events")
    setRoute(`/events/manage/${obj.id}/dashboard`)
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/events/get/${id}`)
    obj={ ...{
      style:{},
      checkpoints:[],
      program:[],
      registerOptions:{},
      onExhibitorScan:{},
      faqs:[]
    }, ...obj }
    return
  }

  isSame("listEvents","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listEvents", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/events/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(obj){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/events/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/events")
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/events/tags/list')
  }

  async function clone(obj){
    $modal={cancel:true, title:`${i18n("events--Duplicate")} ${i18n(obj.title)}`, color:'warning', confirm:async ()=>{
      await api(`/app/customers/events/clone/${obj.id}`)
      list()
      getTags()
    }}
  }

  //Participants
  let listParticipantsKeyword="", listParticipantsSortBy={name:1}, listParticipantsFilter={valid:true}

  //isSame("listParticipants","clean")
  async function listParticipants(...checkArgs){
    //if(checkArgs.length && isSame("listParticipants", checkArgs)) return
    objsA =  await api(`/event/participants/list`, {eventId:obj.id, request:{ ...listParticipantsFilter, ...subevents.length?{subevents:{ $in: subevents}}:null}, keyword:listParticipantsKeyword,sortBy: listParticipantsSortBy})
  }

  async function getParticipant(id){
    objA={}
    if(id) objA=await api(`/event/participants/get/${id}`, {eventId:obj.id})
    objA={ ...objA }
  }

  async function setParticipant(validInform){
    if(validInform) objA.valid=true
    objA.eventId=obj.id
    await api(`/event/participants/set`, {obj: objA, eventId:obj.id, inform:validInform})
    setRoute(`/events/manage/${obj.id}/participants`)
  }

  async function delParticipant(){
    $modal={cancel:true, title:`${i18n("g--Delete")}  ${objA.lastname}`, msg:i18n("g--Confirm delete"), color:'danger', aadangerZone:'yes', confirm:async ()=>{
      let data=await api(`/event/participants/delete/${objA.id}`,{eventId:obj.id})
      setRoute(`/events/manage/${obj.id}/participants`)
    }}
  }

  //Exhibitors
  let listExhibitorsKeyword="", listExhibitorsSortBy={name:1}, listExhibitorsFilter={valid:true}

  //isSame("listExhibitors","clean")
  async function listExhibitors(...checkArgs){
    //if(checkArgs.length && isSame("listExhibitors", checkArgs)) return
    objsA = await api(`/event/exhibitors/list`, {eventId:obj.id, request:{ ...listExhibitorsFilter, ...subevents.length?{subevents:{ $in: subevents}}:null}, keyword:listExhibitorsKeyword,sortBy: listExhibitorsSortBy})
  }

  async function getExhibitor(id){
    objA={}
    if(id) objA=await api(`/event/exhibitors/get/${id}`, {eventId:obj.id})
    objA={ ...objA }
  }

  async function setExhibitor(validInform){
    if(validInform) objA.valid=true
    objA.eventId=obj.id
    await api(`/event/exhibitors/set`, {obj: objA, eventId:obj.id, inform:validInform})
    setRoute(`/events/manage/${obj.id}/exhibitors`)
  }

  async function delExhibitor(){
    $modal={cancel:true, title:`${i18n("g--Delete")}  ${objA.company}`, msg:i18n("g--Confirm delete"), color:'danger', aadangerZone:'yes', confirm:async ()=>{
      let data=await api(`/event/exhibitors/delete/${objA.id}`,{eventId:obj.id})
      setRoute(`/events/manage/${obj.id}/exhibitors`)
    }}
  }


  //others
  let dash={}
  async function getDash(){
    dash = await api('/event/dashboard',{eventId:obj.id})
    if(dash) dash.ok=true
    else dash={}
    return dash
  }


  async function getMeetingsDashboard(){
    return await api(`/event/meetings/dashboard`,{eventId:obj.id})
  }

  async function getScansDashboard(type){
    return await api(`/event/scans/dashboard/${type}`,{eventId:obj.id})
  }

  function downloadParticipants(){
    window.open(`https://api.meetnwin.com/v4/event/participants/export/xlsx?token=${$token}&eventId=${obj.id}`)
  }

	function seeYourTarget(obj) {
    let url
    if(obj.useDomain && obj.fqdn) url=obj.fqdn
    else url=$conf.customerFqdn + "/event/" +obj.shortId
		window.open(`https://${url}`,"Event", "width=1600,height=900,resizable,scrollbars=yes,status=0");
	}
  
  function printBadge(id) {
    let popup
    function closePrint () {
      if ( popup ) {
        popup.close();
      }
    }
    //need qrcode route: printing can be only request from the same domain
    popup = window.open(`https://api.meetnwin.com/v4/event/badge/create/${id}`)
    popup.onbeforeunload = closePrint;
    popup.onafterprint = closePrint;
    popup.focus() // Required for IE
    popup.print()
  }
</script>


<CustomerBanner/>

<!-- EVENTS LIST -->
<Route path="/events/*" pathHide="/events/manage/*" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
        { filter: {valid:true}, title:'g--Active' },
        { filter: {valid:false}, title:'g--Not active' }
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'startDate', title:'g--By Date', desc:false}
      ]}
    />

    <List type="card"  cardShadow cardUp refresh={()=>list()} title="events" {objs}>
      <div slot="header" class="w-100 d-flex justify-content-between" let:obj>
        <div>{i18n(obj.name, $lang)}</div>
      </div>
      <div slot="body" let:obj>
        <div class="row m-3">
          <div class="col-6 text-center pe-0">
            <Img fluid maxh=100 src={obj.logo}/>
            <br><I calendar me/>{dateTimeFormat(obj.startDate,'short')}
          </div> 
          <div class="col-2"></div>
          <div class="col-4 small text-nowrap">
            <div  on:click={()=>edit(obj.id)} on:aaclick={()=>setRoute(`/events/manage/${obj.id}/dashboard`)} 
              class="my-2 pointer text-success"><I me cube/><I18n>Manage</I18n>
            </div>
            <div class="dropdown">
              <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <I me cog/><I18n>Action</I18n>
              </div>
              <ul class="dropdown-menu">
                <li><div class="pointer dropdown-item" on:click={()=>clone(obj)}><I me copy/><I18n>Duplicate</I18n></div></li>                            
                <li><div class="pointer dropdown-item text-danger" on:click={()=>del(obj)}><I me trash/><I18n>Delete</I18n></div></li>
              </ul>
            </div>
          </div>
        </div>
        <div>{#each asArray(obj.tags) as tag}<span class="badge bg-secondary me-1">{tag}</span>{/each}</div>
      </div>
      <div slot="footer" let:obj>
        <div class="pointer" on:click={()=>seeYourTarget(obj)}>https://{obj.useDomain && obj.fqdn?obj.fqdn:$conf.customerFqdn +"/event/" +obj.shortId}</div>
      </div>

    </List>

  </div>
</Route>


<!-- EVENTS MANAGE -->
<Route path="/events/manage/:id?/*" let:routeParams fsContent>
  <!--{#await get(routeParams.id) then}-->
    <div class="container fsContent">
      <Title title="event" detail={obj.name} back="/events"/>

      <LineMenu noAutoselect baseRoute={`/events/${routeParams.id}/manage`} valid
        menus={[
          {route: `/events/manage/${routeParams.id}/dashboard`, name: 'dashboard', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/design`, name: 'design', noReload:true, valid: true},
          {route: `/events/manage/${routeParams.id}/progress`, name: 'progress', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/program`, name: 'program', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/exhibitors`, name: 'exhibitors', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/participants`, name: 'participants', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/agendas`, name: 'agendas', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/leads`, name: 'leads', noReload:true, valid: obj.id},
          {route: `/events/manage/${routeParams.id}/exports`, name: 'exports', noReload:true, valid: obj.id},
        ]}    
      />
  

      <!-- DASHBOARD -->
      <Route path="/events/manage/:id/dashboard" let:routeParams fsContent>
        <Title title="Dashboard"/>
        {#await getDash() then dash}

          <div class="fsScroll">
            <div class="row row-cols-1 row-cols-md-4 g-4">
              {#each [
                {route: `/events/manage/${routeParams.id}/participants`, name: 'Participants', icon:'person-walking', value: dash.participants || 0, color:"success", valid:true},
                {route: `/events/manage/${routeParams.id}/exhibitors`, name: 'Exhibitors', icon:'shop', value: dash.exhibitors || 0, color:"success", valid:true},
                {route: `/events/manage/${routeParams.id}/participants`, name: 'Presents', icon:'right-to-bracket', value: dash.participants_presents || 0, color:"success", valid:true},
                {route: `/events/manage/${routeParams.id}/leads`, name: 'Leads', icon:'badge-check', value: dash.leads || 0, color:"success", valid:true},
                {route: `/events/manage/${routeParams.id}/agendas`, name: 'Meetings interactions', icon:'diagram-project', value: dash.meetings || 0, color:"info", valid:true},
                {route: `/events/manage/${routeParams.id}/agendas`, name: 'Meetings accept', icon:'handshake-simple', value: dash.meetings_accept || 0, color:"success", valid:true},
                {route: `/events/manage/${routeParams.id}/agendas`, name: 'Meetings wait', icon:'hourglass-clock', value: dash.meetings_wait || 0, color:"warning", valid:true},
                {route: `/events/manage/${routeParams.id}/agendas`, name: 'Meetings reject', icon:'ban', value: dash.meetings_reject || 0, color:"danger", valid:true},
              ] as d}
                {#if d.valid}
                  <div class="col" on:click={()=>setRoute(d.route)}>
                    <div class="card h-100 pointer shadow">
                      <div class="card-body">
                        <div class="card-dash">

                          {#if d.type=="right"}
                            <div>
                              <div class="text-{d.color}">{d.value}</div>
                              <div class="text">{i18n(d.name, $lang)}</div>
                            </div>
                            <div class="text-{d.color}"><I icon={d.icon}/></div>
                          {:else}
                            <div class="text-{d.color}"><I icon={d.icon}/></div>
                            <div class="text-end">
                              <div class="text-{d.color}">{d.value}</div>
                              <div class="text">{i18n(d.name, $lang)}</div>
                            </div>
                          {/if}
                        </div>
                      </div>
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
          </div>
        {/await}
      </Route>


      <!-- DESIGN -->
      <Route path="/events/manage/:id?/design" let:routeParams fsContent>
        <div class="anchor mb-3" on:click={anchor}>
          <span><I18n>events--General</I18n></span> |
          <span><I18n>events--Type</I18n></span> |
          <span><I18n>events--Badges</I18n></span> |
          <span><I18n>events--Meetings / Conferences</I18n></span> |
          <span><I18n>events--Zones</I18n></span> |
          <span><I18n>events--Checkpoints</I18n></span> |
          <span><I18n>events--Interests</I18n></span> |
          <span><I18n>events--Activities</I18n></span> |
          <span><I18n>events--Forms</I18n></span> |
          <span><I18n>events--Mails</I18n></span> |
          <span><I18n>events--Options</I18n></span> |
          <span><I18n>events--Legal mentions</I18n></span> |
          <span><I18n>events--Faqs</I18n></span>
        </div>

        <Form fsContent norow>
          {#if obj.id}
            <div class="d-flex justify-content-end">
              <Button validity {update} class="col-auto"/>
            </div>
          {/if}
          <div class="row fsScroll">
            <Input type="hr" label="events--General" icon="info"/>
            <Input class="col-sm-12" bind:value={obj.name} icon="star-shooting" type="text" label="events--name" required/>
            <Input set={new Date()} class="col-sm-3" bind:value={obj.startDate} icon type="date" label="events--From" required/>
            <Input set={new Date()} class="col-sm-3" bind:value={obj.endDate} icon type="date" label="events--To" required/>
            <Input class="col-sm-6" bind:value={obj.freeWhen} icon type="text" label="events--Free when" multilang translator/>
            <Input bind:value={obj.shortDescription} icon="fa-bullhorn" type="text" label="events--Slogan" multilang translator/>
            <Input class="col-sm-12" bind:value={obj.description} rows=4 icon type="textarea" label="events--Description" multilang translator/>
            
            <Input bind:value={obj.organizerName} icon="fa-person-carry-box" type="text" label="events--Organized by" required/>
            
            <Input set="https://via.placeholder.com/150" class="col-sm-3" bind:value={obj.logo} accept="image/png,image/jpeg,image/gif" legend maxSize=0.1 type="file" label="events--Logo" icon="image"/>
            <Input set="https://via.placeholder.com/50" class="col-sm-1" bind:value={obj.icon} accept="image/png,image/jpeg,image/gif" legend maxSize=0.1 type="file" label="events--Icon" icon="image"/>
            <Input class="col-sm-5" bind:value={obj.banner} accept="image/png,image/jpeg,image/gif" type="file" label="events--Banner" legend maxSize=1.5 icon="image"/>
            <Input lg set={true} class="col-sm-3" bind:value={obj.bannerCover} type="switch" option="events--banner cover"/>

            <Input class="col-sm-3" bind:value={obj.organizerLogoCdn} accept="image/png,image/jpeg,image/gif" type="file" legend maxSize=0.1 label="events--Organizer logo" icon="image"/>


            <!-- Html home -->
            <Input lg set={false} class="col-sm-12" bind:value={obj.isHomeHtml} type="switch" option="events--Use home html code"/>
                    
            {#if obj.isHomeHtml}
              <Input class="col-sm-12" bind:value={obj.homeHtml} rows=15 icon="fa-code" type="textarea" label="events--Home html code"/>
              <Input lg set={true} class="col-sm-12" bind:value={obj.isHomeHtmlHideFooter} type="switch" option="events--Hide footer on home"/>
            {/if}


            
            <!-- Colors -->
            <Input class="col-12" type="hr" icon="fa-palette" label="g--Colors"/>
            <Input class="col-12" set={false} disabled bind:value={obj.style.isImageBg} type="switch" option="g--is img bg"/>
            {#if obj.style.isImageBg}
              <Input class="col-4" bind:value={obj.style.imageBgCdn} icon="image" type="file" label="g--image bg" accept="image/svg,image/png,image/jpeg,image/gif"/>
            {:else}
              <Input class="col-4" bind:value={obj.style.colorBg} set="#ffffff" icon type="color" colorText label="g--color bg" />
            {/if}
    
            <Input class="col-4" set="#000000" bind:value={obj.style.colorText} icon type="color" colorText label="g--color text"/>
            <Input class="col-4" set="#007bff" bind:value={obj.style.colorLink} icon type="color" colorText label="g--color link"/>
            <Input class="col-4" set="#ec0909" bind:value={obj.style.brandColor} icon type="color" colorText label="g--brandColor"/>
            <Input class="col-4" set="#000000" bind:value={obj.style.brandColor2} icon type="color" colorText label="g--brandColor2"/>
            <Input class="col-4" set="#FFFFFF" bind:value={obj.style.colorSlogan} icon type="color" colorText label="g--color slogan"/>
            <Input class="col-4" set="#ffffff" bind:value={obj.style.navbarBgColor} icon type="color" colorText label="g--navbarBgColor"/>
            <Input class="col-4" set="#17baa5" bind:value={obj.style.buttonBgColor} icon type="color" colorText label="g--color bg button"/>
            <Input class="col-4" set="#FFFFFF" bind:value={obj.style.buttonTextColor} icon type="color" colorText label="g--color text button"/>
    

            <Input type="hr" label="events--Subevents" icon="fa-tags"/>
            <Inputs useUuid bind:value={obj.subevents} type="text" minInputs=0 nolabel/>
            

            <!-- Type -->
            <Input type="hr" label="events--type" icon="fa-wrench"/>
            <Input lg set={true} class="col-sm-4" bind:value={obj.physical} type="switch" option="events--Event physical"/>
            <Input lg set={false} class="col-sm-8" bind:value={obj.digital} type="switch" option="events--Event digital"/>
                    
            {#if obj.physical}
              <Input class="col-sm-12" bind:value={obj.address} icon type="text" required label="events--address"/>
            {/if}
    
            {#if obj.physical || obj.digital}
              <Input type="hr" label="events--Meetings / Conferences" icon="fa-handshake"/>
              {#if obj.physical}
                <Input lg set={true} class="col-sm-4" bind:value={obj.physicalMeeting} type="switch" option="events--Meetings O2O physical"/>
                <Input lg set={true} class="col-sm-8" bind:value={obj.physicalConference} type="switch" option="events--Conferences physical"/>
              {/if}
              
              {#if obj.digital}
                <Input lg set={true} class="col-sm-4" bind:value={obj.digitalMeeting} type="switch" option="events--Meetings O2O digital"/>
                <Input lg set={true} class="col-sm-8" bind:value={obj.digitalConference} type="switch" option="events--Conferences digital"/>
              {/if}
            {/if}


            <!-- badges -->
            <Input type="hr" label="events--Badges" icon="fa-id-badge"/>
            <Input class="col-12" lg set={false} bind:value={obj.isTemplateBadge} type="switch" option="g--use template badge"/>
            {#if obj.isTemplateBadge}
              <Input class="col-12" bind:value={obj.templateBadgeCdn} icon="pdf" type="file" label="g--badge pdf" helpBefore="<a location=true href='https://cdn.meetnwin.com/assets/badge.pdf?dl=true'>badge.pdf model</a>" accept=".pdf"/>
            {/if}

            <Input class="col-4" set="#66FFB2" bind:value={obj.badgeColorSpeaker} icon type="color" colorText label="event--speaker badge color"/>
            <Input class="col-4" set="#7F00FF" bind:value={obj.badgeColorPress} icon type="color" colorText label="event--press badge color"/>
            <Input class="col-4" set="#33FF33" bind:value={obj.badgeColorVisitor} icon type="color" colorText label="event--visitor badge color"/>
            <Input class="col-4" set="#FF3333" bind:value={obj.badgeColorExhibitor} icon type="color" colorText label="event--exhibitor badge color"/>
            <Input class="col-4" set="#330000" bind:value={obj.badgeColorStaff} icon type="color" colorText label="event--staff badge color"/>

                 
            <Input type="hr" label="events--Zones" icon="fa-grid-horizontal"/>
            <Input lg set={false} class="col-12" bind:value={obj.isZones} type="switch" option="events--is zones"/>
            {#if obj.isZones}
              <Inputs bind:value={obj.zones} icon type="text" useUuid label="events--zones" required/> 
            {/if}
    
    
            <Input type="hr" label="events--Checkpoints" icon="scan"/>
            <Input lg set={false} class="col-12" bind:value={obj.isCheckpoints} type="switch" option="events--is checkpoints"/>
            {#if obj.isCheckpoints}
              <InputsGroup let:i bind:values={obj.checkpoints}>
                <Input set={uuid()} type="hidden" required bind:value={obj.checkpoints[i].id} />
                <Input label="name" class="col-sm-10" type="text" icon="name" required bind:value={obj.checkpoints[i].name} />
                <Input label=" " lg set={true} class="col-sm-2" bind:value={obj.checkpoints[i].valid} type="switch" option="active"/>
                <Input set={true} class="col-sm-3" bind:value={obj.checkpoints[i].participants} type="switch" option="all participants"/>
                <Input set={false} disabled={obj.checkpoints[i].participants} class="col-sm-3" bind:value={obj.checkpoints[i].visitor} type="switch" option="events--visitors"/>
                <Input set={false} disabled={obj.checkpoints[i].participants} class="col-sm-3" bind:value={obj.checkpoints[i].press} type="switch" option="events--press"/>
                <Input set={false} disabled={obj.checkpoints[i].participants} class="col-sm-3" bind:value={obj.checkpoints[i].staff} type="switch" option="participants--staff"/>
                <Input set={false} disabled={obj.checkpoints[i].participants} class="col-sm-3" bind:value={obj.checkpoints[i].exhibitor} type="switch" option="events--exhibitors"/>
                <Input set={false} disabled={obj.checkpoints[i].participants} class="col-sm-3" bind:value={obj.checkpoints[i].speaker} type="switch" option="events--speakers"/>
                <Input set={false} disabled={obj.checkpoints[i].participants} class="col-sm-3" bind:value={obj.checkpoints[i].vip} type="switch" option="participants--vip"/>
                <Input set={false} disabled={obj.checkpoints[i].participants} class="col-sm-3" bind:value={obj.checkpoints[i].sponsor} type="switch" option="events--sponsors"/>
              </InputsGroup>
            {/if}

            <Input type="hr" label="events--Interests" icon="fa-tags"/>
            <Inputs useUuid bind:value={obj.interests} icon type="text" minInputs=0 label="events--interest"/> 
    
            <Input type="hr" label="events--Activities" icon="fa-tags"/>
            <Inputs useUuid bind:value={obj.activities} icon type="text" minInputs=0 label="events--activity"/>

            <Input type="hr" label="events--Services" icon="fa-tags"/>
            <Inputs useUuid bind:value={obj.services} icon type="text" minInputs=0 label="events--service"/>

            <Input type="hr" label="events--Projects" icon="fa-tags"/>
            <Inputs useUuid bind:value={obj.projects} icon type="text" minInputs=0 label="events--project"/>



            <Input type="hr" label="events--Arabie Saoudite special form"/>
            <Input lg set={false} class="col-12" bind:value={obj.isParticipantFormAS} type="switch" option="events--is Arabie Saoudite special form"/>

            {#if obj.isParticipantFormAS}
              <Input type="hr" label="Postes occupés"/>
              <Inputs useUuid bind:value={obj.asPositions} icon type="text" minInputs=0/>

              <Input type="hr" label="NOMBRE DE VOYAGEURS DEJA ENVOYES EN ARABIE SAOUDITE"/>
              <Inputs useUuid bind:value={obj.asNbTravellers} icon type="text" minInputs=0/>

              <Input type="hr" label="VOUS VENDEZ LA DESTINATION ARABIE SAOUDITE SUR LE SEGMENT"/>
              <Inputs useUuid bind:value={obj.asSegmentSold} icon type="text" minInputs=0/>

              <Input type="hr" label="VOUS SOUHAITEZ DEVELOPPER L’ARABIE SUR LE SEGMENT"/>
              <Inputs useUuid bind:value={obj.asSegmentDev} icon type="text" minInputs=0/>
            {/if}


    
            <Input type="hr" label="events--Forms" icon="fa-pen"/>
            <Input set={
              {fr:"Société", en:"Company"}
            } class="col-sm-6" bind:value={obj.participantFormCompanyFieldname} rows=6 icon type="text" label="events--participant form company fieldname" multilang translator/>
    
            <Input class="col-sm-6" lg set={true} bind:value={obj.participantFormShowNetwork} type="switch" option="events--participant form show network field"/>

            <Input set={
              {fr:"Cher exposant,\nMerci de compléter les champs du formulaire ci-dessous ainsi que les CGV à accepter via la case dédiée à cocher.\nUne fois cette étape réalisée, notre équipe en interne vérifiera vos informations, ouvrira votre accès à la plateforme digitale; vous pourrez ainsi commencer à personnaliser votre vitrine exposant."}
            } class="col-sm-12" bind:value={obj.exhibitorFormHead} rows=6 icon type="textarea" label="events--Exhibitor form head" multilang translator/>
    
            <Input set={
              {fr:"Cher Visiteur,\nMerci de compléter les champs du formulaire ci-dessous.\nUne fois cette étape réalisée, notre équipe en interne vérifiera vos informations et ouvrira votre accès à la plateforme digitale, et vous pourrez commencer à personnaliser votre profil.\n"}
            } class="col-sm-12" bind:value={obj.participantFormHead} rows=6 icon type="textarea" label="events--Participant form head" multilang translator/>

            <Input class="col-sm-12" bind:value={obj.participantRecordedMessage} rows=6 icon type="textarea" label="events--Participant recorded message" multilang translator/>


            <Input lg set={false} bind:value={obj.registerParticipantSimplified} type="switch" option="events--Participant simple form"/>


            <Input lg set={false} bind:value={obj.registerParticipantHasQuestion} type="switch" option="events--Participant propose question"/>


            <Input type="hr" label="events--Mails" icon="fa-pen"/>
            <Input lg set={false} bind:value={obj.useMailTemplates} type="switch" option="events--Use mail templates"/>
            {#if obj.useMailTemplates}
              <Input class="col-sm-12" bind:value={obj.template_mail_account_open_subject} icon type="text" label="events--template_mail_account_open_subject" multilang translator/>
              <Input class="col-sm-12" bind:value={obj.template_mail_account_open} rows=6 icon type="textarea" label="events--template_mail_account_open" multilang translator/>

              <Input class="col-sm-12" bind:value={obj.template_mail_account_open_exhibitor_subject} icon type="text" label="events--template_mail_account_open_exhibitor_subject" multilang translator/>
              <Input class="col-sm-12" bind:value={obj.template_mail_account_open_exhibitor} rows=6 icon type="textarea" label="events--template_mail_account_open_exhibitor" multilang translator/>

              <Input class="col-sm-12" bind:value={obj.template_mail_meeting_cancel_subject} icon type="text" label="events--template_mail_meeting_cancel_subject" multilang translator/>
              <Input class="col-sm-12" bind:value={obj.template_mail_meeting_cancel} rows=6 icon type="textarea" label="events--template_mail_meeting_cancel" multilang translator/>

              <Input class="col-sm-12" bind:value={obj.template_mail_meeting_reject_subject} icon type="text" label="events--ttemplate_mail_meeting_reject_subject" multilang translator/>
              <Input class="col-sm-12" bind:value={obj.template_mail_meeting_reject} rows=6 icon type="textarea" label="events--template_mail_meeting_reject" multilang translator/>

              <Input class="col-sm-12" bind:value={obj.template_mail_meeting_request_subject} icon type="text" label="events--template_mail_meeting_request_subject" multilang translator/>
              <Input class="col-sm-12" bind:value={obj.template_mail_meeting_request} rows=6 icon type="textarea" label="events--template_mail_meeting_request" multilang translator/>

              <Input class="col-sm-12" bind:value={obj.template_mail_meeting_schedule_subject} icon type="text" label="events--template_mail_meeting_schedule_subject" multilang translator/>
              <Input class="col-sm-12" bind:value={obj.template_mail_meeting_schedule} rows=6 icon type="textarea" label="events--template_mail_meeting_schedule" multilang translator/>

              <Input class="col-sm-12" bind:value={obj.template_mail_otp_subject} icon type="text" label="events--template_mail_otp_subject" multilang translator/>
              <Input class="col-sm-12" bind:value={obj.template_mail_otp} rows=6 icon type="textarea" label="events--template_mail_otp" multilang translator/>
            {/if}


            <Input type="hr" label="events--Options" icon="fa-cogs"/>        
            <Input class="col-sm-12" bind:value={obj.tags} canAdd options={tags} type="tag" label="g--tags"/>


            <Input lg set={false} bind:value={obj.useDomain} type="switch" option="g--Custom domain"/>
            {#if obj.useDomain}
            <p><I18n>To add your own domain</I18n></p>
            <div class="p-3 bg-light">
              <div class="fsContentHorizontal">
                <div><code>{"your.domain.com" + ' CNAME ' + $conf.cname + '.'}</code> <button disable={!obj.id} class="btn btn-xs btn-outline-secondary" on:click={()=>clipBoard((obj.id ||"your.domain.com") + ' CNAME ' + $conf.cname, true)}><I18n>Copy</I18n></button></div>
              </div>
            </div>
              <Input bind:value={obj.fqdn} required icon type="fqdn" label="g--Domain"/>
            {/if}
    
            <Input lg set={false} bind:value={obj.useGoogleAnalyticsId} type="switch" option="g--use Google analytics"/>
            {#if obj.useGoogleAnalyticsId}
              <Input label="Google Analytics Id" class="col-sm-12" type="text" icon="hash" bind:value={obj.googleAnalyticsId}/>
            {/if}
            <Input lg set={false} bind:value={obj.registerOptions.mailhook} type="switch" option="events--trigger a mail on registration"/>
            {#if obj.registerOptions.mailhook}
              <Inputs bind:value={obj.registerOptions.mailhookTos} icon type="email" label="events--mail to" required maxInputs=4/>
            {/if}
          
            <Input lg set={false} bind:value={obj.registerOptions.webhook} type="switch" option="events--trigger a webhook on registration"/>
            {#if obj.registerOptions.webhook}
              <Inputs bind:value={obj.registerOptions.webhookUrls} icon type="url" label="events--webhook url" required maxInputs=2/>
            {/if}

            <Input lg set={false} bind:value={obj.onExhibitorScan.webhook} type="switch" option="events--trigger a webhook on exhibitor scan"/>
            {#if obj.onExhibitorScan.webhook}
              <Inputs bind:value={obj.onExhibitorScan.webhookUrls} icon type="url" label="events--webhook url" required maxInputs=2/>
            {/if}
    


    
            <Input type="hr" label="events--Contacts" icon="fa-comments-question"/>
            <Input bind:value={obj.organizerMail} icon type="email" label="events--Mail" required/>  
            <Input bind:value={obj.organizerUrl} icon type="url" label="events--Website" required/>   
            <Input bind:value={obj.organizerPhone} icon type="tel" label="events--Phone"/>
    
    
            <Input type="hr" label="events--legal mentions" icon="info"/>
            <Input  bind:value={obj.gdprUrl} icon type="url" label="events--gdpr url"/>   
            <Input bind:value={obj.cguUrl} icon type="url" label="events--cgu url"/>  
    
            <Input type="hr" label="events--faqs" icon="fa-square-question"/>
            <InputsGroup let:i bind:values={obj.faqs}>
              <Input class="col-12" label="question" type="text" icon="question-mark" multilang translator bind:value={obj.faqs[i].question}/>
              <Input class="col-12" label="answer" type="textarea" icon="info" multilang translator bind:value={obj.faqs[i].answer} />
            </InputsGroup>
    
            <div class="row">
              {#if obj.id}
                <Button validity {update} class="col-auto"/>
              {:else}
                <Button validity {update} actionModel="save" class="col-auto"/>
              {/if}
            </div>
          </div>
        </Form>
      </Route>



      <!-- PROGRAM -->
      <Route path="/events/manage/:id/program" let:routeParams fsContent>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="fsScroll">
          <Form>
            <Input type="hr" label="events--Program" icon="calendar-range"/>
            <Input lg set={true} class="col-sm-12" bind:value={obj.slotGap} type="switch" option="events--5mn Gap time" help="events--gap time event help"/>
            <InputsGroup let:i bind:values={obj.program}>
              <Input set={uuid()} type="hidden" bind:value={obj.program[i].id} />

              {#if obj.subevents && obj.subevents.length}
                <Input inCol="col-12" required bind:value={obj.program[i].subevent} type="select" options={obj.subevents} optionsUseValueAsId label="events--subevent" labelBold/>
              {/if}

              <Input label="event--slot name" class="col-sm-10" required type="text" icon="fa-calendar-day" bind:value={obj.program[i].name} multilang translator/>
              <Input label=" " lg set={true} class="col-sm-2" bind:value={obj.program[i].valid} type="switch" option="active"/>
              <Input label="description" class="col-sm-12" type="textarea" icon="info" bind:value={obj.program[i].description} multilang translator/>
              <Input set="meetings" class="col-sm-12" inline bind:value={obj.program[i].type} type="radio" 
                options={[{id:"step", label:"étape"},{id:"period", label:"période"},{id:"meetings", label:"Rendez-vous"},{id:"conference", label:"conférence"}]}
              />
    
              {#if ['meetings','conference'].includes(obj.program[i].type)}
                {#if obj.physical}
                  <Input lg set={true} class="col-sm-3" bind:value={obj.program[i].physical} type="switch" option="events--physical"/>
                {/if}
                {#if obj.digital}
                  <Input lg set={true} class="col-sm-3" bind:value={obj.program[i].digital} type="switch" option="events--digital"/>
                {/if}
              {/if}
              <Input type="empty"/>
    
              <Input set={new Date()} class="col-sm-3" bind:value={obj.program[i].startDate} icon type="datetime" label="events--From"/>
              {#if obj.program[i].type!="step"}
                <Input set={new Date()} class="col-sm-3" bind:value={obj.program[i].endDate} icon type="datetime" label="events--To"/>
              {/if}
              {#if obj.program[i].type=="meetings"}
                <Input set={30} min=10 max=120 class="col-sm-3" bind:value={obj.program[i].slot} icon type="number" label="events--slot duration"/>
              {/if}
            </InputsGroup>

            <div class="row">
              {#if obj.id}
                <Button validity {update} class="col-auto"/>
              {:else}
                <Button validity {update} actionModel="save" class="col-auto"/>
              {/if}
            </div>
          </Form>
        </div>
      </Route>


      <!-- PROGRESS -->
      <Route path="/events/manage/:id/progress" let:routeParams fsContent>
        <div class="anchor mb-3" on:click={anchor}>
          <span><I18n>events--Security</I18n></span> |
          <span><I18n>events--Site menus</I18n></span>
        </div>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="fsScroll">
          <Form>
            <Input type="hr" label="events--Security" icon="fa-lock-alt"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.valid} type="switch" option="events--Active" help="events--Event active help"/>
            <Input lg set={false} disabled={!obj.valid} class="col-sm-8" bind:value={obj.archive} type="switch" option="events--Event archive" help="events--Event archive help"/>
            
            <Input lg set={false} disabled={!obj.valid || obj.archive} class="col-sm-4" bind:value={obj.open} type="switch" option="events--Event open" help="events--Event open help"/>
            
            {#if obj.physicalMeeting || obj.digitalMeeting}
              <Input lg set={false} disabled={!obj.valid || obj.archive} class="col-sm-8" bind:value={obj.appointments} type="switch" option="events--Open appointment booking"/>
            {:else}
              <Input type="empty" class="col-sm-8"/>
            {/if}

            <Input lg set={false} disabled={!obj.valid || obj.archive} class="col-sm-4" bind:value={obj.registerVisitor} type="switch" option="events--Visitors registration"/>
            <Input lg set={true} disabled={!obj.valid || obj.archive || !obj.registerVisitor} class="col-sm-8" bind:value={obj.showRegisterVisitor} type="switch" option="events--Show visitor registration button"/>

            <Input lg set={false} disabled={!obj.valid || obj.archive} class="col-sm-4" bind:value={obj.registerExhibitor} type="switch" option="events--Exhibitors registration"/>
            <Input lg set={true} disabled={!obj.valid || obj.archive || !obj.registerExhibitor} class="col-sm-8" bind:value={obj.showRegisterExhibitor} type="switch" option="events--Show exhibitor registration buttons"/>


            <Input lg set={false} disabled={!$user.perms?.admin} class="col-sm-12" bind:value={obj.test} type="switch" option="events--Mode test" help="events--Mode test help"/>
            

            <Input type="hr" label="events--Site menus" icon="fa-list-check"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showExhibitors} type="switch" option="events--Show exhibitors"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showSpeakers} type="switch" option="events--Show speakers"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showAgenda} type="switch" option="events--Show agenda"/>
            
            <Input lg set={false} class="col-sm-4" bind:value={obj.showLeads} type="switch" option="events--Show leads"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showProgram} type="switch" option="events--Show program"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showParticipants} type="switch" option="events--Show participants"/>
            
            <Input lg set={false} class="col-sm-4" bind:value={obj.showFaq} type="switch" option="events--Show faq"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showInfo} type="switch" option="events--Show info"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showScanner} type="switch" option="events--Show scanner"/>

            <Input lg set={false} class="col-sm-4" bind:value={obj.showExhibitorDownloadLeads} type="switch" option="events--exhibitor download leads button"/>
            <div class="row">
              {#if obj.id}
                <Button validity {update} class="col-auto"/>
              {:else}
                <Button validity {update} actionModel="save" class="col-auto"/>
              {/if}
            </div>
          </Form>
        </div>
      </Route>


      <!-- AGENDAS -->
      <Route path="/events/manage/:id/agendas" let:routeParams fsContent>
          {#await getMeetingsDashboard() }
            <Spinner/>
          {:then dashboard}
            <h1>
              <span class="badge text-bg-success"><I18n>Meetings accept</I18n> {dashboard.accept}</span>
              <span class="badge text-bg-warning"><I18n>Meetings wait</I18n> {dashboard.wait}</span>
              <span class="badge text-bg-danger"><I18n>Meetings reject</I18n> {dashboard.reject}</span>
            </h1>
            
              <List counter title="g--meetings" objs={dashboard.appointements} fields={[
                {id:"receiverCompany", title:"g--applicant"},
                {id:"receiverFirstname", title:"g--firstname"},
                {id:"receiverLastname", title:"g--lastname"},
                {id:"attendeeCompany", title:"g--guest"},
                {id:"attendeeFirstname", title:"g--firstname"},
                {id:"attendeeLastname", title:"g--lastname"},
                {id:"status", title:"g--status"},
                {id:"startDate", title:"g--startDate", format:'shortDt'},
              ]}/>
          
          {:catch}
            <Wrong/>
          {/await}
      </Route>


      <!-- LEADS -->
      <Route path="/events/manage/:id/leads" let:routeParams fsContent>
        <div class="fsScroll">
          <I18n>events--Scanned</I18n>
          {#await getScansDashboard("lead")}
            <Spinner/>
          {:then scans}
            <List title="g--scans" objs={scans} fields={[
              {id:"date", title:"g--scan date", format:'shortDt'},
              {id:"company", title:"g--company"},
              {id:"firstname", title:"g--firstname"},
              {id:"lastname", title:"g--lastname"},
              {id:"userScanBy", title:"event--Scan by"}
            ]}/>
          {:catch}
            <Wrong/>
          {/await}
        </div>
      </Route>

      <!-- EXPORT -->
      <Route path="/events/manage/:id/exports" let:routeParams fsContent>
        <div class="fsScroll">
          <I18n>events--Participants</I18n>
          <button on:click={downloadParticipants} class="btn btn-outline-success">Export participants to Excel</button>
        </div>
      </Route>


      <!-- PARTICIPANTS -->

      <!-- LIST -->
      <Route path="/events/manage/:id/participants/*" let:routeParams fsContent pathHide="/events/manage/:id/participants/edit/:id?">
        
        <h3><I person-walking me/><I18n>Participants</I18n></h3>

        {#if obj.subevents && obj.subevents.length}
          <Input class="col-sm-12" bind:value={subevents} options={obj.subevents} type="tag" ellipsis/>
        {/if}


        <FilterLine search bind:keyword={listParticipantsKeyword} create={()=>setRoute(`/events/manage/${routeParams.id}/participants/edit`)}

          bind:filter={listParticipantsFilter} filters={[
            { filter: {valid:true}, title:'g--Valid' },
            { filter: {valid:false}, title:'g--Not valid' },
            { filter: {present:true}, title:'participants--Present' },
            { filter: {vip:true}, title:'participants--Vip' },
            { filter: {type:"visitor"}, title:'participants--Visitor' },
            { filter: {type:"exhibitor"}, title:'participants--Exhibitor' },
            { filter: {type:"speaker"}, title:'participants--Speaker' },
            { filter: {type:"press"}, title:'participants--Press' },
            { filter: {type:"staff"}, title:'participants--Staff' }
          ]}

          bind:sortBy={listParticipantsSortBy} sortBys={[
            { id:'lastname', title:'g--By name'},
            { id:'company', title:'g--By company'},
            { id:'cDate', title:'g--By date', desc:true}
          ]}
        />

        
        <List let:obj 
          onSelect={(id)=>setRoute(`/events/manage/${routeParams.id}/participants/edit/${id}`)} 
          refresh={()=>listsReload++} title="g--participants" objs={objsA} fields={[
          {id:"valid", title:"g--valid"},
          {id:"present", title:"g--present"},
          {id:"lastname", title:"g--lastname"},
          {id:"firstname", title:"g--firstname"},
          {id:"company", title:"g--company"},
          {id:"type", title:"participants--Type", format:"i18n", i18nPrefix:"participants--"},

          {id:"subevents", title:"Events", format:"tags"},

          {id:"digital", title:"participants--digital"},
          {id:"physical", title:"participants--physical"},
          {id:"cDate", title:"g--subscribed date", format:'shortDt'},
          {id:"vip", title:"participants--Vip"},
          {id:"hasQuestion", title:"Q ?"}
          ]} action>
          
          <div slot="action" title={i18n("participants--print badge")} on:click|stopPropagation={()=>printBadge(obj.id)}><I id-badge/></div>
        </List>
      </Route>


      <!-- EDIT -->
      <Route path="/events/manage/:id/participants/edit/:participantId?" let:routeParams fsContent>
        {#await getParticipant(routeParams.participantId) then}

          <Title title="Participant" detail={`${objA.lastname} ${objA.firstname}`} cancel={`/events/manage/${routeParams.id}/participants`}/>

          <div class="anchor mb-3" on:click={anchor}>
            <span><I18n>participants--General</I18n></span> |
            <span><I18n>participants--Details</I18n></span> |
            <span><I18n>events--consent</I18n></span>
          </div>
          <div class="fsScroll">

            <Form>
              <Input type="hr" label="participants--General" icon="info"/>
              {#if objA.cDate}
                <Input class="col-sm-12" disabled bind:value={objA.cDate} icon="date" type="datetime" label="g--create date"/>
              {/if}
              <Input lg set={true} class="col-sm-4" bind:value={objA.valid} type="switch" option="g--Valid"/>
              <Input lg set={false} class="col-sm-4" bind:value={objA.vip} type="switch" option="participants--Vip"/>
              <Input lg set={false} class="col-sm-4" bind:value={objA.present} type="switch" option="g--Present"/>

              {#if obj.digital}
              <Input lg set={false} class="col-sm-4" bind:value={objA.digital} type="switch" option="participants--Digital"/>
              {/if}
              {#if obj.physical}  
              <Input lg set={true} class="col-sm-4" bind:value={objA.physical} type="switch" option="participants--Physical"/>
              {/if}


              {#if obj.subevents && obj.subevents.length}
                <!--Input inCol="col-12" set={[]} bind:value={objA.subevents} type="switch" options={obj.subevents} optionsUseValueAsId label="events--subevents" labelBold/-->

                <Input inCol="col-12" set={[]} required customValidity={()=>{return objA.subevents && objA.subevents.length>0}} customValidityInvalidMsg="Choisir" bind:value={objA.subevents} type="checkbox" options={obj.subevents} optionsUseValueAsId label="events--subevents" labelBold/>
              {/if}
              
              
              <Input type="hr" label="participants--Details" icon="fa-user"/>
              
              <Input lg set="visitor" inCol="col-lg-2 col-6" bind:value={objA.type} type="radio" options={[
                { id: "visitor", name:"participants--Visitor" },
                { id: "exhibitor", name:"participants--Exhibitor" },
                { id: "speaker", name:"participants--Speaker" },
                { id: "press", name:"participants--Press" },
                { id: "staff", name:"participants--Staff" }
              ]} required icon="fa-id-badge" label="participants--Type"/>

              <Input bind:value={objA.lastname} class="col-lg-5" label="lastname" icon="user" required/>
              <Input bind:value={objA.firstname} class="col-lg-5" label="firstname" icon="user" required/>
              <Input bind:value={objA.lang} class="col-lg-2" set={$lang} icon="fa-globe" type="select" options={$conf.langs} label="Lang" required/>

              {#if objA.type=="exhibitor"} 
                {#await api('/event/exhibitors/list',{eventId:obj.id}) then exhibitors}
                  <Input bind:value={objA.exhibitorId} class="col-lg-6" icon type="select" options={exhibitors} label="Exhibitors--name" required/>
                {/await}
              {:else}
                <Input bind:value={objA.company} class="col-lg-6" label="company" icon/>
              {/if}

              {#if obj.isParticipantFormAS && objA.type=="visitor"}
                <Input class="col-lg-6" required bind:value={objA.asPosition} type="select" optionsUseValueAsId options={obj.asPositions} icon="fa-hat-wizard" label="job title"/>

                {#if objA.asPosition=="Autre"}
                  <Input bind:value={objA.position} class="col-lg-12" label="Précisez la fonction" icon="fa-hat-wizard" required/>
                {/if}
              {:else}
                <Input bind:value={objA.position} class="col-lg-6" label="job title" icon="fa-hat-wizard" required/>
              {/if}




              <Input bind:value={objA.email} class="col-lg-4" type="email" label="email" icon required/>
              <Input bind:value={objA.phone} class="col-lg-4" type="tel" label="phone" icon/>
              <Input bind:value={objA.landlinePhone} class="col-lg-4" label="landline phone" icon/>

              {#if objA.type!="exhibitor" && !obj.registerParticipantSimplified} 
                <Input bind:value={objA.zip} class="col-lg-3" label="zip" icon/>
                <Input bind:value={objA.city} class="col-lg-3" label="city" icon/>
                <Input bind:value={objA.state} class="col-lg-3" label="state" icon/>
                <Input bind:value={objA.country} class="col-lg-3" type="countries" label="country" icon required/>
              {/if} 

        
              <!--
              <Input lg set={true} class="col-sm-2 col-6" bind:value={objA.visitor} type="checkbox" option="participants--Visitor"/>
              <Input lg set={false} class="col-sm-2 col-6" bind:value={objA.exhibitor} type="checkbox" option="participants--Exhibitor"/>
              <Input lg set={false} class="col-sm-2 col-6" bind:value={objA.speaker} type="checkbox" option="participants--Speaker"/>
              <Input lg set={false} class="col-sm-2 col-6" bind:value={objA.press} type="checkbox" option="participants--Press"/>
              <Input lg set={false} class="col-sm-2 col-6" bind:value={objA.staff} type="checkbox" option="participants--Staff"/>
              -->

              <Input type="hr" label="events--consent" icon="info"/>
              <Input disabled bind:value={objA.consent} class="col-12" type="checkbox" option="events--visitor consent" noLabel/>
              
              
              {#if objA.type=="visitor"}
                <Input disabled bind:value={objA.consentNewsletter} class="col-12" type="checkbox" option="events--visitor consent newsletter" noLabel/>
                
                <Input type="hr" label="participants--Visitor details" icon="info"/>
                <Input bind:value={objA.network} class="col-lg-12" label="events--visitor network" help="events--visitor network help" icon/>

                <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={objA.activities} icon="search" type="switch" options={obj.activities} label="events--activities" labelBold/>
                <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={objA.services} icon="search" type="switch" options={obj.services} label="events--services" labelBold/>
                <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={objA.projects} icon="search" type="switch" options={obj.projects} label="events--projects" labelBold/>
                <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={objA.interests} icon="search" type="switch" options={obj.interests} label="events--participant interests" labelBold/>
              {/if}

              {#if obj.isParticipantFormAS && objA.type=="visitor"}
              <Input bind:value={objA.emailContact} class="col-lg-4" type="email" label="E-mail général de l'agence" icon/>
              <Input bind:value={objA.addressPro} class="col-12" label="Adresse postale professionnelle" icon="fa-city"/>

              <Input bind:value={objA.iata} class="col-lg-12" label="events--N° IATA" icon="fa-plane"/>

              <Input bind:value={objA.web} class="col-lg-12" label="Site web de l’agence" icon="fa-link"/>

                <Input type="hr" label="Autres informations" icon="info"/>
                <Input inCol="col-12" required set={"no"} bind:value={objA.alreadyCome} type="radio" options={["no","yes"]} label="Êtes-vous déjà allé(e) en Arabie Saoudite ?"/>
                <Input inCol="col-12" required set={"no"} bind:value={objA.interestedByEductour} type="radio" options={["no","yes"]} label="Seriez-vous intéressé(e) par un eductour en Arabie Saoudite ?"/>
                <Input inCol="col-12" required set={"no"} bind:value={objA.registredOnSaudiExpert} type="radio" options={["no","yes"]} label="Êtes-vous déjà inscrit(e) sur Saudi Expert ?"/>

                <Input inCol="col-12" set={"0"} required bind:value={objA.asNbTravellers} type="radio" options={obj.asNbTravellers} optionsUseValueAsId label="Nombre de voyageurs déjà envoyés en Arabie Saoudite" ?/>
                <Input inCol="col-12" set={[]} required customValidity={()=>{return objA.asSegmentSold && objA.asSegmentSold.length>0}} customValidityInvalidMsg="Choisir" bind:value={objA.asSegmentSold} optionsUseValueAsId type="checkbox" options={obj.asSegmentSold} label="Vous vendez la destination Arabie Saoudite sur le segment"/>
                <Input inCol="col-12" set={[]} required customValidity={()=>{return objA.asSegmentDev && objA.asSegmentDev.length>0}} customValidityInvalidMsg="Choisir" bind:value={objA.asSegmentDev} optionsUseValueAsId type="checkbox" options={obj.asSegmentDev} label="Vous souhaitez développer l’Arabie sur le segment"/>

              {/if}

              {#if objA.type=="exhibitor"}
                <Input type="hr" label="participants--Exhibitor details" icon="info"/>
                <!--Input bind:value={objA.exhibitorId} icon type="text" label="participants--Exhibitor name" required/-->
                <Input lg set={false} class="col-sm-12" bind:value={objA.referral} type="switch" option="events--referral contact"/>
              {/if}

              {#if objA.type=="speaker"}
                <Input type="hr" label="participants--Speaker details" icon="info"/>
                <Input lg set={false} bind:value={objA.speakerTop} type="switch" option="participants--Speaker top"/>
                <Input bind:value={objA.speakerBio} icon type="textarea" label="participants--Speaker bio"/>
              {/if}

              {#if objA.type=="press"}
                <Input type="hr" label="participants--Press details" icon="fa-newspaper"/>
                <!--Input class="col-sm-6" bind:value={objA.pressName} icon type="text" label="participants--Media name" required/-->          
                <Input class="col-sm-12" set={[]} inline bind:value={objA.pressType} type="checkbox" options={[
                  { id: "newspaper", name:"Newspaper" },
                  { id: "digital", name:"Digital" },
                  { id: "other", name:"other" }
                ]} label="participants--Media type"/>
              {/if}

              {#if objA.type=="staff"}
                <Input type="hr" label="participants--Staff details" icon="info"/>
                <Input set={[]} inCol="col-lg-4 col-sm-6" bind:value={objA.staffRoles} type="checkbox" options={[
                  { id: "scan", name:"participants--Role scan" },
                  { id: "welcome", name:"participants--Role welcome" },
                  { id: "client", name:"participants--Role client" },
                  { id: "concierge", name:"participants--Role concierge" },
                  { id: "cloakroom", name:"participants--Role cloakroom" },
                  { id: "security", name:"participants--Role security" },
                  { id: "provider", name:"participants--Role provider" },
                  { id: "cleaner", name:"participants--Role cleaner" },
                  { id: "other", name:"participants--Role other" }
                ]} label="participants--Staff roles"/>
              {/if}
              
              {#if objA.id}
                <Button validity action={delParticipant} actionModel="delete" class="col-auto"/>
                <Button validity action={()=>setParticipant()} actionModel="update" class="col-auto"/>
              {:else}
                <Button validity action={()=>setParticipant()} actionModel="save" class="col-auto"/>
              {/if}
              {#if !objA.valid}
                <Button validity action={()=>setParticipant(true)} title="events--Valid & inform" class="col-auto"/>
              {/if}
            </Form>
          </div>
        {:catch}
          <Wrong/>
        {/await}
      </Route>

      <!-- EXHIBITORS -->

      <!-- LIST -->
      <Route path="/events/manage/:id/exhibitors" let:routeParams fsContent pathHide="/events/manage/:id/exhibitors/edit/:id">
        <h3><I shop me/><I18n>Exhibitors</I18n></h3>

        {#if obj.subevents && obj.subevents.length}
          <Input class="col-sm-12" bind:value={subevents} options={obj.subevents} type="tag" ellipsis/>
        {/if}

        <FilterLine search bind:keyword={listExhibitorsKeyword} create={()=>setRoute(`/events/manage/${routeParams.id}/exhibitors/edit`)}

          bind:filter={listExhibitorsFilter} filters={[
            { filter: {valid:true}, title:'g--Valid' },
            { filter: {valid:false}, title:'g--Not valid' },
            { filter: {digital:true}, title:'exhibitors--digital' },
            { filter: {physical:true}, title:'exhibitors--physical' },
            { filter: {sponsor:true}, title:'exhibitors--Sponsor' },
            { filter: {pinned:true}, title:'exhibitors--Pinned' }
          ]}

          bind:sortBy={listExhibitorsSortBy} sortBys={[
            { id:'name', title:'g--By name'},
            { id:'cDate', title:'g--By date', desc:true}
          ]}
        />

        <List let:obj 
          onSelect={(id)=>setRoute(`/events/manage/${routeParams.id}/exhibitors/edit/${id}`)} 
          refresh={()=>listsReload++} title="g--exhibitors" objs={objsA} fields={[
            {id:"name", title:"exhibitors--brand"},
            {id:"cDate", title:"g--subscribed date", format:'shortDt'},

            {id:"subevents", title:"Events", format:"tags"},

            {id:"digital", title:"exhibitors--digital"},
            {id:"physical", title:"exhibitors--physical"},
            {id:"standRef", title:"exhibitors--stand reference"},
            {id:"sponsor", title:"exhibitors--sponsor"},
            {id:"pinned", title:"exhibitors--pinned"},
            {id:"valid", title:"g--valid"}
          ]}>
        </List>
      </Route>


      <!-- EDIT -->
      <Route path="/events/manage/:id/exhibitors/edit/:exhibitorId?" let:routeParams fsContent>
        {#await getExhibitor(routeParams.exhibitorId) then}

          <Title title="Exhibitor" detail={objA.name} cancel={`/events/manage/${routeParams.id}/exhibitors`}/>

          <div class="anchor mb-3" on:click={anchor}>
            <span><I18n>exhibitors--General</I18n></span> |
            <span><I18n>exhibitors--Details</I18n></span> |
            <span><I18n>company</I18n></span> |
            <span><I18n>events--billing address</I18n></span> |
            <span><I18n>events--consent</I18n></span> |
            <span><I18n>events--team</I18n></span> |
            <span><I18n>exhibitors--Collaborators</I18n></span> |
            <span><I18n>events--interests</I18n></span>

          </div>
          <div class="fsScroll">

            <Form>
              <Input type="hr" label="exhibitors--General" icon="info"/>
              {#if objA.cDate}
                <Input class="col-sm-12" disabled bind:value={objA.cDate} icon="date" type="datetime" label="g--create date"/>
              {/if}
              <Input class="col-sm-12" bind:value={objA.name} icon="company" type="text" label="exhibitors--brand" required/>
              <Input lg set={true} class="col-sm-4" bind:value={objA.valid} type="switch" option="g--Valid"/>
              {#if obj.digital}
                <Input lg set={false} class="col-sm-4" bind:value={objA.digital} type="switch" option="exhibitors--Digital"/>
              {/if}
              {#if obj.physical}  
                <Input lg set={true} class="col-sm-4" bind:value={objA.physical} type="switch" option="exhibitors--Physical"/>
              {/if}

              {#if obj.subevents && obj.subevents.length}
                <!--Input inCol="col-12" set={[]} bind:value={objA.subevents} type="switch" options={obj.subevents} optionsUseValueAsId label="events--subevents" labelBold/-->
                <Input inCol="col-12" set={[]} required customValidity={()=>{return objA.subevents && objA.subevents.length>0}} customValidityInvalidMsg="Choisir" bind:value={objA.subevents} type="checkbox" options={obj.subevents} optionsUseValueAsId label="events--subevents" labelBold/>
              {/if}
              
              <Input lg set={false} class="col-sm-4" bind:value={objA.sponsor} type="switch" option="exhibitors--Official sponsor"/>
              <Input lg set={false} class="col-sm-4" bind:value={objA.pinned} type="switch" option="exhibitors--Pinned"/>
              
              {#if obj.zones && obj.zones.length}
                <Input inCol="col-lg-4 col-sm-6 col-12" set="" bind:value={objA.zones} icon="fa-grid-horizontal" type="radio" options={obj.zones} label="event--zones"/>
              {/if}


              {#if obj.physical}  
                <Input class="col-sm-12" bind:value={objA.standRef} icon="shop" type="text" label="events--stand reference"/>
              {/if}
            
              

              <Input class="col-lg-12" bind:value={objA.tags} canAdd options={tags} icon type="tag" label="g--tags" help="g--tags help"/>


              <Input type="hr" label="exhibitors--Details" icon="info"/>
              <Input class="col-12" bind:value={objA.shortDescription} maxlength=150 icon="minimize" type="text" label="exhibitors--Short description" help="exhibitors--Short description help" multilang translator/>
              <Input class="col-12" bind:value={objA.description} rows=5 icon type="textarea" label="exhibitors--Description" multilang translator/>
              <Input class="col-sm-4" bind:value={objA.logo} legend maxSize=0.5 accept="image/png,image/jpeg,image/gif,image/svg" type="file" label="exhibitors--Logo" icon/>
              <Input class="col-sm-4" bind:value={objA.videoCdn} maxSize=30 legend accept="video/*" type="file" label="exhibitors--Video" icon/>
              <Input class="col-sm-4" bind:value={objA.pdfCdn} maxSize=5 legend accept="application/pdf" type="file" label="exhibitors--Pdf" icon/>
              <Input class="col-12" bind:value={objA.videoLink} icon type="url" label="exhibitors--external video link" help="exhibitors--external video link help"/>
              <Input class="col-12" bind:value={objA.imagesCdn} multiple maxValues="5" icon accept="image/png,image/jpeg,image/gif,image/svg" type="file" label="exhibitors--Images" /> 
              <Input class="col-12" bind:value={objA.website} icon type="url" label="g--Website"/>
              
              <Input type="hr" label="company" icon="info"/>
              <Input bind:value={objA.company} class="col-lg-4" label="events--company name" icon/>
              <Input bind:value={objA.vat} class="col-lg-4" label="VAT number" icon="vat"/>
              <Input bind:value={objA.phone} class="col-lg-4" label="landline phone" icon="phone"/>
              <Input bind:value={objA.address} class="col-lg-12" label="address" icon/>
              <Input bind:value={objA.zip} class="col-lg-3" label="zip" icon/>
              <Input bind:value={objA.city} class="col-lg-3" label="city" icon/>
              <Input bind:value={objA.state} class="col-lg-3" label="state" icon/>
              <Input bind:value={objA.country} class="col-lg-3" type="countries" label="country" icon/>
              
              <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={objA.activities} icon="search" type="switch" options={obj.activities} label="exhibitors--activities"/>

              <Input type="hr" label="events--billing address" icon="info"/>
              <Input lg set={false} class="col-12" bind:value={objA.isBillingAdress} type="switch" option="events--use billing address"/>
              {#if objA.isBillingAdress}
                <Input bind:value={objA.billing.address} class="col-lg-12" label="address" icon required/>
                <Input bind:value={objA.billing.zip} class="col-lg-3" label="zip" icon required/>
                <Input bind:value={objA.billing.city} class="col-lg-3" label="city" icon required/>
                <Input bind:value={objA.billing.state} class="col-lg-3" label="state" icon required/>
                <Input bind:value={objA.billing.country} class="col-lg-3" type="countries" label="country" icon required/>
                <Input bind:value={objA.billing.name} class="col-lg-4" label="lastname" icon required/>
                <Input bind:value={objA.billing.email} class="col-lg-4" type="email" label="email" icon required/>
                <Input bind:value={objA.billing.phone} class="col-lg-4" label="phone" icon required/>
              {/if}

              <Input type="hr" label="events--consent" icon="info"/>
              <Input disabled bind:value={objA.consent} class="col-12" type="checkbox" option="events--exhibitor consent" noLabel/>
              

              <Input type="hr" label="events--team" icon="info"/>

              <Input set={1} min=1 max=50 class="col-sm-3" bind:value={objA.maxTeam} icon type="number" label="exhibitors--max team"/>

            
              
              <Input type="hr" label="exhibitors--Collaborators" icon="users"/>
              <Input lg set={true} class="col-12" bind:value={objA.isTeam} type="switch" option="exhibitors--is team"/>
              {#if objA.isTeam}
                <InputsGroup let:i bind:values={objA.team} maxInputs={objA.maxTeam}>
                  <Input type="hidden" bind:value={objA.team[i].id} />
                  <Input lg set={false} class="col-sm-12" bind:value={objA.team[i].referral} type="switch" option="events--referral contact"/>
                  <Input label="lastname" class="col-sm-5" type="text" icon="name" required bind:value={objA.team[i].lastname}/>
                  <Input label="firstname" class="col-sm-5" type="text" icon="name" required bind:value={objA.team[i].firstname} />
                  <Input label="Lang" class="col-sm-2"  type="select" icon="fa-globe" required bind:value={objA.team[i].lang} options={$conf.langs}/>
                  <Input label="position" class="col-sm-6" type="text" icon="position" required bind:value={objA.team[i].position} />
                  <Input label="email" class="col-sm-6" type="email" icon required bind:value={objA.team[i].email} />
                  <Input label="phone" class="col-sm-6" type="tel" icon required bind:value={objA.team[i].phone} />
                  <Input label="landline phone" class="col-sm-6" type="tel" icon="phone" bind:value={objA.team[0].landlinePhone}/>
                  {#if obj.subevents && obj.subevents.length}
                  <!--Input inCol="col-12" set={objA.subevents} bind:value={objA.team[0].subevents} type="switch" options={$conf.subevents} optionsUseValueAsId label="events--subevents" labelBold/-->
                    <Input inCol="col-12" set={objA.subevents} required customValidity={()=>{return objA.team[0] && objA.team[0].subevents && objA.team[0].subevents.length>0}} customValidityInvalidMsg="Choisir" bind:value={objA.team[0].subevents} type="checkbox" options={obj.subevents} optionsUseValueAsId label="events--subevents" labelBold/>
                  {/if}
                </InputsGroup>
              {/if}

              <Input type="hr" label="events--interests" icon="info"/>
              <Input inCol="col-lg-4 col-sm-6 col-12" set={[]} bind:value={objA.interests} icon="search" type="switch" options={obj.interests} label="events--exhibitor interests"/>

              
              {#if objA.id}
                <Button validity action={delExhibitor} actionModel="delete" class="col-auto"/>
                <Button validity action={setExhibitor} actionModel="update" class="col-auto"/>
              {:else}
                <Button validity action={setExhibitor} actionModel="save" class="col-auto"/>
              {/if}
              <!--
                {#if !objA.valid}
                <Button validity action={()=>setExhibitor(true)} title="events--Valid & inform" class="col-auto"/>
              {/if}
              -->
            </Form>
          </div>
        {:catch}
          <Wrong/>
        {/await}
      </Route>


    </div>
 <!-- {:catch}
    <Wrong/>
  {/await}-->
</Route>

<style>

  .card-dash {
    line-height:2.5rem;
    font-size:3rem;
    display: flex;
    justify-content: space-between;
  }
  .text{
    line-height:1rem;
    font-size:1rem;
  }
</style>