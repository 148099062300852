<script>
  import { permissions, customers, customerId, route, wsc, conf, userId, user, token, lang, alert, menus } from './stores.js'
  import { setRoute, setRouteRoot} from './h.js'

  import Window from './G_Window.svelte'
  import Route from './G_Route.svelte'

  import Dashboard from './L_Dashboard.svelte'
  import Events from './L_Events.svelte'
  import Plans from './L_Plans.svelte'
  
  
  let localConf={
    faType:"fa-light",
    url:"https://meetnwin.com",
    langs:['fr','en'],
    i18n:{},
    perms:[
      {id:"users", name:"member--manage team"}
    ],
    permsAgency:[
      {id:"customers", name:"agency--manage agency customers"},
      {id:"users", name:"agency--manage agency users"}
    ],
    account:{
      withDomains:false,
      withBilling:true,
      withApi:false,
      withDPO:false,
      withComparePlan:false,
      withLicence:true,
      withLicenceDetail:true
    },
    agency:{
      withLicenceAgencyCustomer:true,
      withLicenceAgencyCustomerDetail:true
    },
    cname:"customer.meetnwin.com"
  }

  $: $menus=[
    {route: '/dashboard', name: '', icon:'home', valid:$customers},
    {route: '/homenocustomer', name: '', icon:'home', valid:!$customers},
    {route: '/events', name: 'Events', icon:'calendar-star', valid:$customers},
    {route: '/plans', name: 'Plans', icon:'gift', valid:$customers},
    {route: '/account', name: 'Account', icon:'wrench', valid:$permissions.admin || $permissions.users || $permissions.informations},
    {route: '/agency', name: 'Agency', icon:'globe', valid:$user.perms?.valid},
    {route: '/translations', name: 'Translations', icon:'university', valid:$user.perms?.translation}
  ]
</script>

<Window {localConf}>
  {#key $customerId}
    <Route path="/homenocustomer" fsContent>
      Hello home no customer
    </Route>

    <Route path="/dashboard" fsContent>
      <Dashboard/>
    </Route>

    <Route path="/events/*" fsContent>
      <Events/>
    </Route>

    <Route path="/plans/*" fsContent>
      <Plans/>
    </Route>
  {/key}
</Window>