<script>
  import { conf, permissions, modal, user, lang, token, route  } from './stores.js'
  import { api, cdn, translate, i18n, setRoute, matchRoute, sleep, inArray, asArray, isSame, getById, genCode, anchor, uuid, dateTimeFormat, clipBoard } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Inputs from './G_Inputs.svelte'
  import InputsGroup from './G_InputsGroup.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import Img from './G_Img.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import LineMenu from './G_LineMenu.svelte'
  import Spinner from './G_Spinner.svelte'
  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], objsA=[], obj={}, objA={}, keyword="", sortBy={name:1}, filter={valid:true}, tagsSelected=[]

  let listsReload=0

  $: list(keyword, filter, tagsSelected, sortBy)

  $: if(matchRoute($route,'/plans/manage/:id/distributions')) listDistributions(listDistributionsKeyword, listDistributionsSortBy, listDistributionsFilter, listsReload)
  $: if(matchRoute($route,'/plans/manage/:id/exchanges')) listExchanges(listExchangesKeyword, listExchangesSortBy, listExchangesFilter, listsReload)
  $: if(matchRoute($route,'/plans/manage/:id/accounts')) listAccounts(listAccountsKeyword, listAccountsSortBy, listAccountsFilter, listsReload)


  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.plans) $modal={
      cancel:true,
      title:i18n("licence--No more plans"), 
      msg:i18n("licence--No more plans msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else{
      await get()
      setRoute("/plans/manage//design")
    }
  }



  async function edit(id){
    await get(id)
    setRoute(`/plans/manage/${id}/dashboard`)
  }

  async function update(){
    obj = await api(`/app/customers/plans/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    //edit(obj.id)
    //setRoute("/plans")
    setRoute(`/plans/manage/${obj.id}/dashboard`)
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/plans/get/${id}`)
    obj={ ...{
      style:{},
      faqs:[{}]
    }, ...obj }
    return
  }

  isSame("listPlans","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listPlans", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/plans/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(obj){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/plans/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/plans")
    }}
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/plans/tags/list')
  }

  async function clone(obj){
    await api(`/app/customers/plans/clone/${obj.id}`)
    //await sleep(100)
    list()
    getTags()
    //setRoute("/plans")
  }

  //Distributions
  let listDistributionsKeyword="", listDistributionsSortBy={name:1}, listDistributionsFilter={valid:true}

  //isSame("listDistributions","clean")
  async function listDistributions(...checkArgs){
    //if(checkArgs.length && isSame("listDistributions", checkArgs)) return
    objsA =  await api(`/plan/distributions/list`, {planId:obj.id, request:listDistributionsFilter, keyword:listDistributionsKeyword,sortBy: listDistributionsSortBy})
  }

  async function getDistribution(id){
    objA={}
    if(id) objA=await api(`/plan/distributions/get/${id}`, {planId:obj.id})
    objA={ ...objA }
  }

  async function setDistribution(validInform){
    if(validInform) objA.valid=true
    objA.planId=obj.id
    await api(`/plan/distributions/set`, {obj: objA, planId:obj.id, inform:validInform})
    setRoute(`/plans/manage/${obj.id}/distributions`)
  }

  async function delDistribution(){
    $modal={cancel:true, title:`${i18n("g--Delete")}  ${objA.name}`, msg:i18n("g--Confirm delete"), color:'danger', aadangerZone:'yes', confirm:async ()=>{
      let data=await api(`/plan/distributions/delete/${objA.id}`,{planId:obj.id})
      setRoute(`/plans/manage/${obj.id}/distributions`)
    }}
  }

  //Exchanges
  let listExchangesKeyword="", listExchangesSortBy={name:1}, listExchangesFilter={valid:true}

  //isSame("listExchanges","clean")
  async function listExchanges(...checkArgs){
    //if(checkArgs.length && isSame("listExchanges", checkArgs)) return
    objsA =  await api(`/plan/exchanges/list`, {planId:obj.id, request:listExchangesFilter, keyword:listExchangesKeyword,sortBy: listExchangesSortBy})
  }

  async function getExchange(id){
    objA={}
    if(id) objA=await api(`/plan/exchanges/get/${id}`, {planId:obj.id})
    objA={ ...objA }
  }

  async function setExchange(validInform){
    if(validInform) objA.valid=true
    objA.planId=obj.id
    await api(`/plan/exchanges/set`, {obj: objA, planId:obj.id, inform:validInform})
    setRoute(`/plans/manage/${obj.id}/exchanges`)
  }

  async function delExchange(){
    $modal={cancel:true, title:`${i18n("g--Delete")}  ${objA.name}`, msg:i18n("g--Confirm delete"), color:'danger', aadangerZone:'yes', confirm:async ()=>{
      let data=await api(`/plan/exchanges/delete/${objA.id}`,{planId:obj.id})
      setRoute(`/plans/manage/${obj.id}/exchanges`)
    }}
  }

  //Accounts
  let listAccountsKeyword="", listAccountsSortBy={name:1}, listAccountsFilter={valid:true}

  //isSame("listAccounts","clean")
  async function listAccounts(...checkArgs){
    //if(checkArgs.length && isSame("listAccounts", checkArgs)) return
    objsA =  await api(`/plan/accounts/list`, {planId:obj.id, request:listAccountsFilter, keyword:listAccountsKeyword,sortBy: listAccountsSortBy})
  }

  async function getAccount(id){
    objA={}
    if(id) objA=await api(`/plan/accounts/get/${id}`, {planId:obj.id})
    objA={ ...objA }
  }

  async function setAccount(validInform){
    if(validInform) objA.valid=true
    objA.planId=obj.id
    await api(`/plan/accounts/set`, {obj: objA, planId:obj.id, inform:validInform})
    setRoute(`/plans/manage/${obj.id}/accounts`)
  }

  //others
  let dash={}
  async function getDash(){
    dash = await api('/plan/dashboard',{planId:obj.id})
    if(dash) dash.ok=true
    else dash={}
    return dash
  }

	function seeYourTarget(obj) {
    let url
    if(obj.useDomain && obj.fqdn) url=obj.fqdn
    else url=$conf.customerFqdn + "/plan/" +obj.shortId
		window.open(`https://${url}`,"Plan", "width=1600,height=900,resizable,scrollbars=yes,status=0");
	}
</script>


<CustomerBanner/>

<!-- PLANS LIST -->
<Route path="/plans/*" pathHide="/plans/manage/*" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
        { filter: {valid:true}, title:'g--Active' },
        { filter: {valid:false}, title:'g--Not active' }
      ]}

      bind:sortBy sortBys={[
        //{ id:'name', title:'g--By name'},
        //{ id:'date', title:'g--By date', desc:true}
      ]}
    />

    <List type="card"  cardShadow cardUp refresh={()=>list()} title="plans" {objs}>
      <div slot="header" class="w-100 d-flex justify-content-between" let:obj>
        <div>{i18n(obj.name, $lang)}</div>
      </div>
      <div slot="body" let:obj>
        <div class="row m-3">
          <div class="col-6 text-center pe-0">
            <Img fluid maxh=100 src={obj.logo}/>
          </div> 
          <div class="col-2"></div>
          <div class="col-4 small text-nowrap">
            <div  on:click={()=>edit(obj.id)} on:aaclick={()=>setRoute(`/plans/manage/${obj.id}/dashboard`)} 
              class="my-2 pointer text-success"><I me cube/><I18n>Manage</I18n>
            </div>
            <div class="dropdown">
              <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <I me cog/><I18n>Action</I18n>
              </div>
              <ul class="dropdown-menu">
                <li><div class="pointer dropdown-item" on:click={()=>clone(obj)}><I me copy/><I18n>Duplicate</I18n></div></li>                            
                <li><div class="pointer dropdown-item text-danger" on:click={()=>del(obj)}><I me trash/><I18n>Delete</I18n></div></li>
              </ul>
            </div>
          </div>
        </div>
        <div>{#each asArray(obj.tags) as tag}<span class="badge bg-secondary me-1">{getById(tags,tag).name}</span>{/each}</div>
      </div>
      <div slot="footer" let:obj>
        <div class="pointer" on:click={()=>seeYourTarget(obj)}>https://{obj.useDomain && obj.fqdn?obj.fqdn:$conf.customerFqdn +"/plan/" +obj.shortId}</div>
      </div>

    </List>

  </div>
</Route>


<!-- PLANS MANAGE -->
<Route path="/plans/manage/:id?/*" let:routeParams fsContent>
  <!--{#await get(routeParams.id) then}-->
    <div class="container fsContent">
      <Title title="plan" detail={obj.name} back="/plans"/>

      <LineMenu noAutoselect baseRoute={`/plans/${routeParams.id}/manage`} valid
        menus={[
          {route: `/plans/manage/${routeParams.id}/dashboard`, name: 'dashboard', noReload:true, valid: obj.id},
          {route: `/plans/manage/${routeParams.id}/design`, name: 'design', noReload:true, valid: true},
          {route: `/plans/manage/${routeParams.id}/progress`, name: 'progress', noReload:true, valid: obj.id},
          {route: `/plans/manage/${routeParams.id}/distributions`, name: 'distribution', noReload:true, valid: obj.id},
          {route: `/plans/manage/${routeParams.id}/exchanges`, name: 'exchange', noReload:true, valid: obj.id},
          {route: `/plans/manage/${routeParams.id}/accounts`, name: 'accounts', noReload:true, valid: obj.id},
        ]}    
      />
  

      <!-- DASHBOARD -->
      <Route path="/plans/manage/:id/dashboard" let:routeParams fsContent>
        <Title title="Dashboard"/>
        {#await getDash() then dash}

          <div class="fsScroll">
            <div class="row row-cols-1 row-cols-md-4 g-4">
              {#each [
                {route: `/plans/manage/${routeParams.id}/distributions`, name: 'Distributions method', icon:'hand-holding', value: dash.distributions || 0, color:"success", valid:true},
                {route: `/plans/manage/${routeParams.id}/exchanges`, name: 'Exchanges method', icon:'right-left', value: dash.exchanges || 0, color:"success", valid:true},
                {route: `/plans/manage/${routeParams.id}/accounts`, name: 'Accounts', icon:'file-user', value: dash.accounts || 0, color:"success", valid:true},
                {route: `/plans/manage/${routeParams.id}/design`, name: 'Initial volume points', icon:'sack', value: dash.volume || 0, color:"success", valid:true},
                {route: `/plans/manage/${routeParams.id}/accounts`, name: 'Distributed points', icon:'hand-holding-box', value: dash.points_distributed || 0, color:"success", valid:true},
                {route: `/plans/manage/${routeParams.id}/accounts`, name: 'Exchanged points', icon:'gifts', value: dash.points_exchanged || 0, color:"warning", valid:true},
                {route: `/plans/manage/${routeParams.id}/design`, name: 'Remaining points', icon:'coins', value: dash.volume_remaining || 0, color:"success", valid:true}
              ] as d}
                {#if d.valid}
                  <div class="col" on:click={()=>setRoute(d.route)}>
                    <div class="card h-100 pointer shadow">
                      <div class="card-body">
                        <div class="card-dash">

                          {#if d.type=="right"}
                            <div>
                              <div class="text-{d.color}">{d.value}</div>
                              <div class="text">{i18n(d.name, $lang)}</div>
                            </div>
                            <div class="text-{d.color}"><I icon={d.icon}/></div>
                          {:else}
                            <div class="text-{d.color}"><I icon={d.icon}/></div>
                            <div class="text-end">
                              <div class="text-{d.color}">{d.value}</div>
                              <div class="text">{i18n(d.name, $lang)}</div>
                            </div>
                          {/if}
                        </div>
                      </div>
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
          </div>
        {/await}
      </Route>


      <!-- DESIGN -->
      <Route path="/plans/manage/:id?/design" let:routeParams fsContent>
        <div class="anchor mb-3" on:click={anchor}>
          <span><I18n>plans--General</I18n></span> |
          <span><I18n>g--Colors</I18n></span> |
          <span><I18n>plans--Security</I18n></span> |
          <span><I18n>plans--Contacts</I18n></span> |
          <span><I18n>plans--legal mentions</I18n></span> |
          <span><I18n>plans--faqs</I18n></span> |
          <span><I18n>plans--Options</I18n></span>
        </div>

        <Form fsContent norow>
          {#if obj.id}
            <div class="d-flex justify-content-end">
              <Button validity {update} class="col-auto"/>
            </div>
          {/if}
          <div class="row fsScroll">

            <Input type="hr" label="plans--General" icon="info"/>
            <Input class="col-sm-8" bind:value={obj.name} icon type="text" label="plans--name" multilang translator required/>
            <Input class="col-sm-4" bind:value={obj.pointName}
              pattern={"(?!dollars|livre|E£|الجنيه|المصري|EGP|dinar|LYD|dirham|د.م.|MAD|ouguiya|أوقية|MRU|s£|pounds|SDG|DT|TND|Franc|CFA|UEMOA|XOF|escudo|CVE|dalasi|GMD|cedi|₵|¢|GHS|franc|F|GNF|dollar|LRD|naira|N|NGN|leone|SLL|FC|CDF|CEMAC|XAF|dobra|STD|BIF|DJF|nakfa|ERN|birr|ETB|KES|shilling|UGX|RWF|roupie|SCR|SOS|SSP|TZS|rand|R|ZAR|kwanza|Kz|AOA|pula|BWP|couronne|krone|NOK|فرنك|قمري|KMF|euro|€|EUR|loti|LSL|ariary|MGA|kwacha|MWK|MUR|metical|MTn|MZN|NAD|£|SHP|lilangeni|SZL|kwacha|ZMW|ZWL|tenge|tеңге|KZT|som|som|KGS|sum|sum|UZS|somoni|somoni|TJS|manat|manat|TMT|dram|դրամ|AMD|manat|₼|AZN|lari|₾|ლარი|GEL|rouble|₽|р|руб|рубль|RUB|yuan|renminbi|¥|元|CNY|won|￦|Wn|KPW|W|KRW|HKD|yen|¥|円|JPY|pataca|MOP|tugrik|₮|төгрөг|MNT|元|NT$|TWD|afghani|؋|AFN|riyal|SAR|dinar|BHD|dirham|DH|درهم|إماراتي|AED|rial|ریال|ایران|IRR|dinar|IQD|shekel|₪|ILS|JOD|KWD|£L|LBP|rial|OMR|QAR|S£|SYP|₺|lira|TRY|YER|kyat|kyat|MMK|B$|BND|riel|KHR|rupiah|IDR|kip|₭|LAK|ringgit|RM|MYR|peso|piso|PHP|S$|SGD|baht|฿|บาท|THB|dollar|$|USD|dong|VND|taka|BDT|ngultrum|Nu|BTN|roupie|Rs|Re|रू|रुपया|INR|₹|rufiyaa|rf|ދިވެހި|ރުފިޔ|MVR|रूपैयाँ|NPR|₨|PKR|₨|LKR|BD$|BMD|CA|CAD|kroner|kr|DKK|MXN|BZ$|BZD|colon|colón|CRC|quetzal|GTQ|lempira|HNL|oro|córdoba|NIO|balboa|balboa|PAB|EC$|XCD|florin|Afl|AFL|Aƒ|ƒ|AWG|B$|BSD|Bds$|BBD|CI$|KYD|CUP|CUC|NAƒ|NAf|ƒ|f|ANG|DOP|EC$|XCD|gourde|HTG|J$|JMD|TTD|titis|ARS|boliviano|BOB|réal|R$|reais|BRL|CLP|COP|£|FKP|G$|GYD|guaraní|₲|PYG|nuevo|sol|S|PEN|SRD|UYU|bolívar|VES)([a-z0-9A-Z ]+)"} 
              placeholder="plans--Pointname-placeholder" icon type="text" required label="plans--Point name"/>
       
            <Input bind:value={obj.shortDescription} icon="fa-bullhorn" type="text" label="plans--Slogan" multilang translator/>
            <Input class="col-sm-12" bind:value={obj.description} rows=4 icon type="textarea" label="plans--Description" multilang translator/>
            
            <Input bind:value={obj.organizerName} icon="fa-person-carry-box" type="text" label="plans--Organized by" required/>
            
            <Input set="https://via.placeholder.com/150" class="col-sm-3" bind:value={obj.logo} accept="image/png,image/jpeg,image/gif" legend maxSize=0.1 type="file" label="events--Logo" icon="image"/>
            <Input class="col-sm-6" bind:value={obj.banner} accept="image/png,image/jpeg,image/gif" type="file" label="plans--Banner" legend maxSize=0.5 icon="image"/>
            <Input class="col-sm-3" bind:value={obj.organizerLogoCdn} accept="image/png,image/jpeg,image/gif" type="file" legend maxSize=0.1 label="plans--Organizer logo" icon="image"/>
            
            <!-- Colors -->
            <Input class="col-12" type="hr" icon="fa-palette" label="g--Colors"/>
            <Input class="col-12" set={false} disabled bind:value={obj.style.isImageBg} type="switch" option="g--is img bg"/>
            {#if obj.style.isImageBg}
              <Input class="col-4" bind:value={obj.style.imageBgCdn} icon="image" type="file" label="g--image bg" accept="image/svg,image/png,image/jpeg,image/gif"/>
            {:else}
              <Input class="col-4" set="#FFFFFF" bind:value={obj.style.colorBg}  icon type="color" colorText label="g--color bg" />
            {/if}
    
            <Input class="col-4" set="#000000" bind:value={obj.style.colorText} icon type="color" colorText label="g--color text"/>
            <Input class="col-4" set="#007bff" bind:value={obj.style.colorLink} icon type="color" colorText label="g--color link"/>
            <Input class="col-4" set="#ec0909" bind:value={obj.style.brandColor} icon type="color" colorText label="g--brandColor"/>
            <Input class="col-4" set="#000000" bind:value={obj.style.brandColor2} icon type="color" colorText label="g--brandColor2"/>
            <Input class="col-4" set="#FFFFFF" bind:value={obj.style.colorSlogan} icon type="color" colorText label="g--color slogan"/>
            <Input class="col-4" set="#ffffff" bind:value={obj.style.navbarBgColor} icon type="color" colorText label="g--navbarBgColor"/>
            <Input class="col-4" set="#17baa5" bind:value={obj.style.buttonBgColor} icon type="color" colorText label="g--color bg button"/>
            <Input class="col-4" set="#FFFFFF" bind:value={obj.style.buttonTextColor} icon type="color" colorText label="g--color text button"/>
    

            <Input type="hr" label="plans--Security" icon="fa-lock-alt"/>
            <Input class="col-sm-6" set=5000 min={obj.maxByAccount || 1} max=50000 required bind:value={obj.volume} icon type="number" label="plans--Volume"/>
            <Input class="col-sm-6" set=50 min=1 max={obj.volume} required bind:value={obj.maxByAccount} icon type="number" label="plans--Max by account"/>
             
            
            <Input type="hr" label="plans--Contacts" icon="fa-comments-question"/>
            <Input bind:value={obj.organizerMail} icon type="email" label="plans--Mail" required/>  
            <Input bind:value={obj.organizerUrl} icon type="url" label="plans--Website" required/>   
            <Input bind:value={obj.organizerPhone} icon type="tel" label="plans--Phone"/>
    
    
            <Input type="hr" label="plans--legal mentions" icon="info"/>
            <Input  bind:value={obj.gdprUrl} icon type="url" label="plans--gdpr url"/>   
            <Input bind:value={obj.cguUrl} icon type="url" label="plans--cgu url"/>  
    
            <Input type="hr" label="plans--faqs" icon="fa-square-question"/>
            <InputsGroup let:i bind:values={obj.faqs}>
              <Input class="col-12" label="question" type="text" icon="question-mark" multilang translator bind:value={obj.faqs[i].question}/>
              <Input class="col-12" label="answer" type="textarea" icon="info" multilang translator bind:value={obj.faqs[i].answer} />
            </InputsGroup>
            
            
            
            <Input type="hr" label="plans--Options" icon="fa-layer-plus"/>
            <Input lg set={false} bind:value={obj.useDomain} type="switch" option="plans--Custom domain"/>
            {#if obj.useDomain}
            <p><I18n>To add your own domain</I18n></p>
            <div class="p-3 bg-light">
              <div class="fsContentHorizontal">
                <div><code>{"your.domain.com" + ' CNAME ' + $conf.cname + '.'}</code> <button disable={!obj.id} class="btn btn-xs btn-outline-secondary" on:click={()=>clipBoard((obj.id ||"your.domain.com") + ' CNAME ' + $conf.cname, true)}><I18n>Copy</I18n></button></div>
              </div>
            </div>
            <Input bind:value={obj.fqdn} required icon type="fqdn" label="plans--Domain"/>
              {/if}

    
            <div class="row">
              {#if obj.id}
                <Button validity {update} class="col-auto"/>
              {:else}
                <Button validity {update} actionModel="save" class="col-auto"/>
              {/if}
            </div>
          </div>
        </Form>
      </Route>


      <!-- PROGRESS -->
      <Route path="/plans/manage/:id/progress" let:routeParams fsContent>
        <div class="anchor mb-3" on:click={anchor}>
          <span><I18n>plans--Security</I18n></span> |
          <span><I18n>plans--Site menus</I18n></span>
        </div>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="fsScroll">
          <Form>
            <Input type="hr" label="plans--Security" icon="fa-lock-alt"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.valid} type="switch" option="plans--Active" help="plans--Active help"/>
            
            <Input lg set={false} disabled={!obj.valid} class="col-sm-4" bind:value={obj.open} type="switch" option="plans--Open" help="plans--Open help"/>
 
            <Input lg set={false} class="col-sm-4" disabled={!obj.valid} bind:value={obj.distribute} type="switch" option="plans--Distribution opened"/>
            <Input lg set={false} class="col-sm-4" disabled={!obj.valid} bind:value={obj.exchange} type="switch" option="plans--Exchange opened"/>
   

            <Input lg set={false} disabled={!obj.valid} class="col-sm-12" bind:value={obj.test} type="switch" option="plans--Mode test" help="plans--Mode test help"/>
            

            <Input type="hr" label="events--Site menus" icon="fa-list-check"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showAccount} type="switch" option="plans--Show exhibitors"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showExchange} type="switch" option="plans--Show agenda"/>
            
            <Input lg set={false} class="col-sm-4" bind:value={obj.showFaq} type="switch" option="plans--Show faq"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showInfo} type="switch" option="plans--Show info"/>
            <Input lg set={false} class="col-sm-4" bind:value={obj.showScanner} type="switch" option="plans--Show scanner"/>
            <div class="row">
              {#if obj.id}
                <Button validity {update} class="col-auto"/>
              {:else}
                <Button validity {update} actionModel="save" class="col-auto"/>
              {/if}
            </div>
          </Form>
        </div>
      </Route>


      <!-- DISTRIBUTIONS -->

      <!-- LIST -->
      <Route path="/plans/manage/:id/distributions/*" let:routeParams fsContent pathHide="/plans/manage/:id/distributions/edit/:id?">
        
        <h3><I person-walking me/><I18n>Distributions</I18n></h3>
        <FilterLine search bind:keyword={listDistributionsKeyword} create={()=>setRoute(`/plans/manage/${routeParams.id}/distributions/edit`)}

          bind:filter={listDistributionsFilter} filters={[
            { filter: {valid:true}, title:'g--Active' },
            { filter: {valid:false}, title:'g--Not active' },
          ]}

          bind:sortBy={listDistributionsSortBy} sortBys={[
            { id:'name', title:'g--By name'},
            { id:'startDate', title:'g--By date', desc:true}
          ]}
        />

        
        <List let:obj 
          onSelect={(id)=>setRoute(`/plans/manage/${routeParams.id}/distributions/edit/${id}`)} 
          refresh={()=>listsReload++} title="g--distributions" objs={objsA} fields={[
            {id:"name", title:"g--name"},
            {id:"valid", title:"g--valid"},
            {id:"points", title:"distributions--Credited points"},
            {id:"volume", title:"distributions--Limit"},
            {id:"maxOccurences", title:"distributions--Max occurences by account"}          
          ]}>          
          </List>
      </Route>


      <!-- EDIT -->
      <Route path="/plans/manage/:id/distributions/edit/:distributionId?" let:routeParams fsContent>
        {#await getDistribution(routeParams.distributionId) then}

          <Title title="Distribution" detail={`${objA.name}`} cancel={`/plans/manage/${routeParams.id}/distributions`}/>

          <div class="anchor mb-3" on:click={anchor}>
            <span><I18n>distributions--General</I18n></span> |
            <span><I18n>distributions--Security</I18n></span> |
            <span><I18n>distributions--Actions</I18n></span>
          </div>
          <div class="fsScroll">

            <Form>
              <Input type="hr" label="distributions--General" icon="info"/>

              <Input class="col-sm-8" bind:value={objA.name} icon type="text" label="distributions--Name" required/>
              <Input class="col-sm-4" readonly bind:value={objA.distributed} icon type="number" label="distributions--Distributed"/>
              <Input class="col-sm-12" bind:value={objA.description} icon type="text" label="distributions--Description"/>

              <Input type="hr" label="distributions--Security" icon="fa-lock-alt"/>
              <Input lg set={true} class="col-sm-3" bind:value={objA.valid} type="switch" option="g--Active" label=" "/>
              <Input set=1 min=1 max={objA.volume?objA.volume:obj.volume} class="col-sm-3" bind:value={objA.points} icon type="number" label="distributions--Credited points"/>
              <Input set={Math.round(obj.volume * 10 /100) || 0} min=0 max={obj.volume} class="col-sm-3" bind:value={objA.volume} icon type="number" label="distributions--Limit" help="0 = no limit" />
              <Input set=0 min=0 max={objA.volume || obj.volume} class="col-sm-3" bind:value={objA.maxOccurences} icon type="number" label="distributions--Max occurences by account" help="0 = no limit" />
            
              <Input inline set={{}} class="col-lg-12" bind:value={objA.uses} options={[ 
                { name: "Email", id: "email" },
                { name: "Api", id: "api"},
                { name: "Scan", id: "scan" },
                { name: "Email opened", id: "emailopened" },
                { name: "Checkpoint", id: "checkpoint" },
                { name: "Bulk", id: "bulk" }
              ]} icon type="checkbox" label="distributions--Triggers"/>
              
              {#if objA.uses && objA.uses.email}
                <Inputs bind:value={objA.emailFroms} icon type="email" label="email From" help="authorized emails" required maxInputs=10/>         
                <div>
                  <I18n>Send an email</I18n><br>
                  <code>
                    <p>To: auto@meetnwin.com</p>
                    <p>
                      With subject: {objA.id}
                      <button class="btn btn-sm btn_outline-primary" on:click={()=>clipBoard(`${objA.id}`)}><I copy/> Copy</button>
                    </p>
                    <p>In content, put email of account to distribute: user@domain.com</p>
                  </code>
                  <a href="mailto:auto@meetnwin.com?subject={objA.id}&body=user@domain.com">Example</a>
                </div>
              {/if}
              
              {#if objA.uses && objA.uses.api}
                <Input set={uuid()} minLength=32 maxLength=32 bind:value={objA.apiKey} icon type="text" label="apiKey" required/>
                <div>
                  <I18n>Call api</I18n><br>
                  <code>{$conf.apiUrl}/plan/distribution/{objA.apiKey}/{objA.id}/user@domain.com</code>
                  <button class="btn btn-sm btn_outline-primary" on:click={()=>clipBoard(`${$conf.apiUrl}/plan/distribution/${objA.apiKey}/${objA.id}/user@domain.com`)}><I copy/> Copy</button>
                </div>
              {/if}

              {#if objA.uses && objA.uses.emailopened}
                <div>
                  <I18n>Insert this code in your html mail, remplace user@domain.com by the right email</I18n>
                  <code>&lt;img src="{$conf.apiUrl}/plan/distribution/emailopened/{objA.id}/user@domain.com" alt="distribution"&gt;</code>
                  <button class="btn btn-sm btn_outline-primary" on:click={()=>clipBoard(`<img src="${$conf.apiUrl}/plan/distribution/emailopened/${objA.id}/user@domain.com" alt="distribution">`)}><I copy/> Copy</button>
                </div>
              {/if}
      
              <Input lg set={true} class="col-sm-3" bind:value={objA.createAccount} type="switch" option="distributions--Create account" help="Create account if not existent"/>

              <Input type="hr" label="distributions--Actions" icon="fa-cogs"/>
              <Input lg set={false} bind:value={objA.mailhook} type="switch" option="trigger a mail"/>
              {#if objA.mailhook}
                <Inputs bind:value={objA.mailhookTos} icon type="email" label="mail to" required maxInputs=3/>
              {/if}
              
              <Input lg set={false} bind:value={objA.webhook} type="switch" option="trigger a webhook"/>
              {#if objA.webhook}
                <Inputs bind:value={objA.webhookUrls} icon type="url" label="webhook url" required  maxInputs=3/>
              {/if}
              
              {#if objA.id}
                <Button validity action={delDistribution} actionModel="delete" class="col-auto"/>
                <Button validity action={()=>setDistribution()} actionModel="update" class="col-auto"/>
              {:else}
                <Button validity action={()=>setDistribution()} actionModel="save" class="col-auto"/>
              {/if}
              {#if !objA.valid}
                <Button validity action={()=>setDistribution(true)} title="plans--Valid & inform" class="col-auto"/>
              {/if}
            </Form>
          </div>
        {:catch}
          <Wrong/>
        {/await}
      </Route>

      <!-- EXCHANGES -->

      <!-- LIST -->
      <Route path="/plans/manage/:id/exchanges" let:routeParams fsContent pathHide="/plans/manage/:id/exchanges/edit/:id">
        <h3><I person-walking me/><I18n>Exchanges</I18n></h3>
        <FilterLine search bind:keyword={listExchangesKeyword} create={()=>setRoute(`/plans/manage/${routeParams.id}/exchanges/edit`)}

          bind:filter={listExchangesFilter} filters={[
            { filter: {valid:true}, title:'g--Active' },
            { filter: {valid:false}, title:'g--Not active' },
          ]}

          bind:sortBy={listExchangesSortBy} sortBys={[
            { id:'name', title:'g--By name'},
            { id:'startDate', title:'g--By date', desc:true}
          ]}
        />

        <List let:obj 
          onSelect={(id)=>setRoute(`/plans/manage/${routeParams.id}/exchanges/edit/${id}`)} 
          refresh={()=>listsReload++} title="g--exchanges" objs={objsA} fields={[
            {id:"name", title:"g--name"},
            {id:"valid", title:"g--valid"},
            {id:"type", title:"g--type"},
            {id:"points", title:"g--required"},
            {id:"used", title:"g--used"},
            {id:"exchanged", title:"g--exchanged"}
          ]}>
        </List>
      </Route>


      <!-- EDIT -->
      <Route path="/plans/manage/:id/exchanges/edit/:exchangeId?" let:routeParams fsContent>
        {#await getExchange(routeParams.exchangeId) then}

          <Title title="Exchange" detail={objA.name} cancel={`/plans/manage/${routeParams.id}/exchanges`}/>

          <div class="anchor mb-3" on:click={anchor}>
            <span><I18n>exchanges--General</I18n></span> |
            <span><I18n>exchanges--Rule</I18n></span> |
            <span><I18n>exchanges--Actions</I18n></span>

          </div>
          <div class="fsScroll">

            <Form>
              <Input type="hr" label="exchanges--General" icon="info"/>

              <Input class="col-sm-6" bind:value={objA.name} icon type="text" label="exchanges--Name" required/>
              <Input class="col-sm-3" readonly bind:value={objA.used} icon type="number" label="exchanges--Number of exchange"/>
              <Input class="col-sm-3" readonly bind:value={objA.exchanged} icon type="number" label="exchanges--Exchanged points"/>
              <Input class="col-sm-9" bind:value={objA.description} icon type="textarea" label="exchanges--Description" required multilang translator/>
              <Input class="col-sm-3" bind:value={objA.logo} accept="image/png,image/jpeg,image/gif,image/svg" type="file" label="exchanges--logo" icon/>
      
              
              <Input type="hr" label="exchanges--Rule" icon="fa-exchange-alt"/>
              <Input lg set={true} class="col-sm-3" label=" " bind:value={objA.valid} type="switch" option="g--Active"/>
              <Input set=1 min=1 class="col-sm-3" bind:value={objA.points} required icon type="number" label="exchanges--Required points"/>
              <Input inline class="col-sm-6" bind:value={objA.type} options={[ 
                  { name: "Codes", id: "otc" },
                  { name: "Coupon", id: "coupon" },
                  { name: "External api", id: "external" }
                ]} icon type="radio" label="exchanges--Type"/>
              {#if objA.type=="coupon"}<Input class="col-sm-12" bind:value={objA.coupon} icon type="text" label="exchanges--Coupon" required/>{/if}
              {#if objA.type=="otc"}<Input class="col-sm-12" bind:value={objA.otc} icon type="textarea" label="exchanges--Codes" required/>{/if}
              {#if objA.type=="external"}
                <Input bind:value={objA.apiUrl} class="col-sm-6" icon type="url" label="exchanges--API URL" required/>
                <Input bind:value={objA.apiKey} class="col-sm-6" icon type="text" label="exchanges--API key" required/>
              {/if}
          
              <Input type="hr" label="exchanges--Actions" icon="fa-cogs"/>
              <Input lg set={false} bind:value={objA.mailhook} type="switch" option="trigger a mail"/>
              {#if objA.mailhook}
                <Inputs bind:value={objA.mailhookTos} icon type="email" label="mail to" required maxInputs=3/>
              {/if}
              
              <Input lg set={false} bind:value={objA.webhook} type="switch" option="trigger a webhook"/>
              {#if objA.webhook}
                <Inputs bind:value={objA.webhookUrls} icon type="url" label="webhook url" required maxInputs=3/>
              {/if}
              
              {#if objA.id}
                <Button validity action={delExchange} actionModel="delete" class="col-auto"/>
                <Button validity action={setExchange} actionModel="update" class="col-auto"/>
              {:else}
                <Button validity action={setExchange} actionModel="save" class="col-auto"/>
              {/if}
              <!--
                {#if !objA.valid}
                <Button validity action={()=>setExchange(true)} title="plans--Valid & inform" class="col-auto"/>
              {/if}
              -->
            </Form>
          </div>
        {:catch}
          <Wrong/>
        {/await}
      </Route>



      <!-- ACCOUNTS -->

      <!-- LIST -->
      <Route path="/plans/manage/:id/accounts" let:routeParams fsContent pathHide="/plans/manage/:id/accounts/edit/:id">
        <h3><I person-walking me/><I18n>Accounts</I18n></h3>
        <FilterLine search bind:keyword={listAccountsKeyword} create={()=>setRoute(`/plans/manage/${routeParams.id}/accounts/edit`)}

          bind:filter={listAccountsFilter} filters={[
            { filter: {valid:true}, title:'g--Active' },
            { filter: {valid:false}, title:'g--Not active' },
            { filter: {exchanged:{$gt:0}}, title:'accounts--exchanged' }
          ]}

          bind:sortBy={listAccountsSortBy} sortBys={[
            { id:'date', title:'g--By date', desc:true},
            { id:'email', title:'g--By email'},
            { id:'balance', title:'g--By balance', desc:true},
            { id:'exchanged', title:'g--By exchanged', desc:true}
          ]}
        />

        <List let:obj 
          onSelect={(id)=>setRoute(`/plans/manage/${routeParams.id}/accounts/edit/${id}`)} 
          refresh={()=>listsReload++} title="g--accounts" objs={objsA} fields={[
            {id:"shortId", title:"accounts--Number"},
            {id:"valid", title:"g--valid"},
            {id:"email", title:"g--Email"},
            {id:"balance", title:"accounts--Balance"},
            {id:"exchanged", title:"accounts--Exchanged"},
            {id:"date", title:"accounts--Last activity", format:"dt"}
          ]}>
        </List>
      </Route>


      <!-- EDIT -->
      <Route path="/plans/manage/:id/accounts/edit/:accountId?" let:routeParams fsContent>
        {#await getAccount(routeParams.accountId) then}

          <Title title="Account" detail={objA.name} cancel={`/plans/manage/${routeParams.id}/accounts`}/>

          <div class="anchor mb-3" on:click={anchor}>
            <span><I18n>accounts--General</I18n></span> |
            <span><I18n>accounts--Details</I18n></span>
          </div>
          <div class="fsScroll">

            <Form>
              <Input type="hr" label="accounts--General" icon="fa-user"/>
              <Input class="col-sm-4" readonly bind:value={objA.shortId} icon="hashtag" type="text" label="accounts--Number"/>
              <Input class="col-sm-8" bind:value={objA.email} icon type="email" label="g--Email" required/>
              
              <Input type="hr" label="accounts--Details" icon="fa-tally"/>
              <Input lg set={true} class="col-sm-2" bind:value={objA.valid} type="switch" option="g--Active" label=" "/>
              <Input readonly set=0 min=0 class="col-sm-5" bind:value={objA.balance} icon type="number" label="accounts--Balance"/>
              <Input readonly set=0 min=0 class="col-sm-5" bind:value={objA.exchanged} icon type="number" label="accounts--Exchanged"/> 
              
              {#if objA.id}
                <Button validity action={setAccount} actionModel="update" class="col-auto"/>
              {:else}
                <Button validity action={setAccount} actionModel="save" class="col-auto"/>
              {/if}
              <!--
                {#if !objA.valid}
                <Button validity action={()=>setAccount(true)} title="plans--Valid & inform" class="col-auto"/>
              {/if}
              -->
            </Form>
            <div class="card mt-4">
              <div class="card-body">
                <h5><I18n>g--History</I18n></h5>
                {#await api(`/plan/logs/list/${objA.id}`, { planId: obj.id})}
                    <Spinner/>
                {:then logs}
                  <List noHover counter objs={logs} fields={[
                    {id:"date", title:"g--date", format:dateTimeFormat},
                    {id:"valid", title:"g--valid"},
                    {id:"code", title:"g--Code"},
                    {id:"points", title:"accounts--Points"}
                  ]}/>
                {/await}
              </div>
            </div>
          </div>
        {:catch}
          <Wrong/>
        {/await}
      </Route>


    </div>
 <!-- {:catch}
    <Wrong/>
  {/await}-->
</Route>

<style>

  .card-dash {
    line-height:2.5rem;
    font-size:3rem;
    display: flex;
    justify-content: space-between;
  }
  .text{
    line-height:1rem;
    font-size:1rem;
  }
</style>